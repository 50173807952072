import React, { useState } from "react";

function Faqs() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqData = [
    {
      question: "What is LARIS software?",
      answer:
        "LARIS is a comprehensive pathology laboratory management software designed to streamline lab operations, enhance efficiency, and improve patient care.",
    },
    {
      question: "How many editions of LARIS software?",
      answer:
        "LARIS offers three editions: Basic, Advanced, and Premium, each catering to different lab sizes and operational needs.",
    },
    {
      question: "What are modules of Basic Edition?",
      answer:
        "The Basic Edition includes modules for reception, phlebotomist, technologist, pathologist, and admin, offering essential features for managing lab operations.",
    },
    {
      question: "Which modules are unique in Advanced Edition?",
      answer:
        "In addition to the modules in the Basic Edition, the Advanced Edition includes modules for procurement and accounts management, offering enhanced functionalities for financial and purchase management.",
    },
    {
      question: "Which modules are unique in Premium Edition?",
      answer:
        "The Premium Edition includes all modules from the Advanced Edition and introduces two new modules: inventory control and HR management, along with a complimentary integrated website for patient appointments and reports.",
    },
    {
      question: "Can I upgrade from one edition to another?",
      answer:
        "Yes, you can upgrade from one edition to another based on your lab's evolving needs and requirements.",
    },
    {
      question: "Is LARIS software customizable?",
      answer:
        "Yes, LARIS software is customizable to accommodate specific lab workflows and preferences, ensuring flexibility and adaptability.",
    },
    {
      question: "How does LARIS improve lab efficiency?",
      answer:
        "LARIS software automates routine tasks, streamlines workflow processes, and provides real-time data access, resulting in improved efficiency and productivity.",
    },
    {
      question: "Is LARIS compliant with regulatory standards?",
      answer:
        "Yes, LARIS software is designed to meet regulatory standards and compliance requirements, ensuring data integrity, security, and patient confidentiality.",
    },
    {
      question: "Can LARIS integrate with other software Programs?",
      answer:
        "Yes, LARIS software can integrate with other laboratory instruments, information systems, and third-party software for seamless data exchange and interoperability.",
    },
    {
      question: "How does LARIS software handle specimen tracking?",
      answer:
        "LARIS software enables efficient specimen tracking from collection to analysis, ensuring chain of custody and specimen integrity throughout the process.",
    },
    {
      question: "What reporting capabilities does LARIS offer?",
      answer:
        "LARIS software provides customizable reporting options for test results, for different departments of a pathology lab including hematology, chemistry, microbiology, and histopathology. It gives up to 10 previous results of the same parameter to review the trend of improvement or deterioration, for any given disease condition.",
    },
    {
      question: "Is training provided for using LARIS software?",
      answer:
        "Yes, training sessions and resources are available to help users learn and effectively utilize LARIS software features and functionalities.",
    },
    {
      question: "Can multiple users access LARIS simultaneously?",
      answer:
        "Yes, LARIS software supports multi-user access with role-based permissions, allowing simultaneous usage by different team members.",
    },
    {
      question: "How secure is LARIS software?",
      answer:
        "LARIS software employs robust security measures, including encryption, and access controls, to ensure data security and integrity.",
    },
    {
      question: "Can I access LARIS software remotely?",
      answer:
        "Yes, LARIS software can be accessed remotely via secure login credentials, allowing users to manage lab operations from anywhere with an internet connection.",
    },
    {
      question: "Does LARIS software offer support services?",
      answer:
        "Yes, LARIS software comes with comprehensive support services, including technical assistance, software updates, and troubleshooting assistance.",
    },
    {
      question: "Can I customize reports in LARIS software?",
      answer:
        "Yes, LARIS software offers report customization through numerous filter options to tailor reports according to specific requirements and preferences.",
    },
    {
      question: "How does LARIS handle inventory management?",
      answer:
        "LARIS software provides inventory management features to track stock levels, manage supplies, and optimize inventory control for efficient lab operations.",
    },
    {
      question: "Can LARIS help with financial management?",
      answer:
        "Yes, LARIS software includes features for procurement, accounts management, and financial reporting to support effective financial management.",
    },
    {
      question: "Compare advantages of Basic vs Premium?",
      answer:
        "The Premium Edition includes additional modules for inventory control, HR management, and an integrated website with patient portal, offering expanded functionalities and comprehensive solutions for larger labs.",
    },
    {
      question: "How does its Patient portal benefit patients?",
      answer:
        "The patient portal in the Premium Edition allows patients to schedule appointments, view lab reports, and access health information conveniently online, enhancing patient engagement and satisfaction.",
    },
    {
      question: "How does LARIS software handle user permissions?",
      answer:
        "LARIS software allows administrators to customize user roles, permissions, and access levels to ensure data security and compliance with privacy regulations.",
    },
  ];

  return (
    <div>
      <section id="faq" className="ptb-100 white-light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading text-center mb-5">
                <h2 className="laris">Frequently Asked Queries</h2>
                {/* <p>
                  Efficiently productivate reliable paradigms before ubiquitous
                  models. Continually utilize frictionless expertise whereas
                  tactical relationships. Still have questions? Contact us
                </p> */}
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            {/* <div className="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                                <div className="img-wrap">
                                    <img src="assets/img/health.png" alt="download" className="img-fluid" />
                                </div>
                            </div> */}
            <div className="col-md-12 col-lg-12">
              <div className="row">
                {faqData.map((item, index) => (
                  <div className="col-md-6">
                    <div id="accordion" className="accordion faq-wrap">
                      <div className="card my-3" key={index}>
                        <div
                          className={`card-header  ${
                            activeAccordion === index ? "laris-color " : "collapsed"
                          }`}
                          onClick={() => toggleAccordion(index)}
                        >
                          <h6 className="mb-0 d-inline-block">
                            {item.question}
                          </h6>
                        </div>
                        <div
                          className={`collapse ${
                            activeAccordion === index ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body white-bg">
                            <p>{item.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faqs;
