import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';

function NotFound404() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <div className="main">
                <section className="ptb-100 bg-image full-height" image-overlay="8">
                    <div className="background-image-wraper" style={{ background: `url('assets/img/cta-bg.jpg')`, opacity: "1" }}></div>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-9 col-lg-7">
                                <div className="error-content-wrap text-center text-white">
                                    <div className="notfound-404">
                                        <h1 className="text-white">404</h1>
                                    </div>
                                    <h2 className="text-white">Sorry, something went wrong</h2>
                                    <p className="lead">The page you are looking for might have been removed had its name changed or is temporarily
                                        unavailable.</p><Link className="btn btn-brand-03" to="/">Go to Homepage</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default NotFound404;
