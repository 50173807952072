import React, { useEffect, useState } from "react";
import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Faqs from "../PharmacySoftware/Faqs";
import Testimonial from "../PharmacySoftware/Testimonial";
import ScreenCarousal from "../PharmacySoftware/ScreenCarousal";
import PricingTableSecond from "../Components/PricingTableSecond";

function PharmacySoftware() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  var BreadCrumbTitle = "Pharmacy Software :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqData = [
    {
      question: "What deployment options does ClinTa MAPS offer?",
      answer:
        "ClinTa MAPS is available for both online and offline operations, ensuring seamless functionality regardless of internet status.",
    },
    {
      question: "What system options are available with ClinTa MAPS?",
      answer:
        "You can choose between a comprehensive pharmacy management system or a streamlined point-of-sale (POS) setup to best suit your operational needs.",
    },
    {
      question: "What invoicing interfaces are provided by ClinTa MAPS?",
      answer:
        "ClinTa MAPS offers Fast Invoicing Interface (FII) for quick transactions and Batch Invoicing Interface (BII) for efficient batch handling.",
    },
    {
      question: "How does ClinTa MAPS handle purchase requests?",
      answer:
        "It includes an Auto-Purchase Request (APR) module for automated stock replenishment and a Manual Purchase Request (MPR) module for manual control.",
    },
    {
      question: "Can I control access levels in ClinTa MAPS?",
      answer:
        "Yes, ClinTa MAPS provides flexible access levels that can be set based on roles and permissions, enhancing security and operational control.",
    },
    {
      question: "What types of dashboards does ClinTa MAPS offer?",
      answer:
        "It offers Multiple lntegrated Dashboards (MID) for comprehensive oversight and Single Informative Dashboard (SID) for focused management.",
    },
    {
      question: "How does ClinTa MAPS manage invoicing?",
      answer:
        "It features Prescription Independent Invoicing (PII) for seamless prescription management and Prescription Dependent Invoicing (PDI) for traditional invoicing.",
    },
    {
      question: "Is ClinTa MAPS scalable?",
      answer:
        "Yes, ClinTa MAPS scales effortlessly with your business, accommodating both small local pharmacies and large pharmacy chains.",
    },
    {
      question: "What are the key components of MAPS?",
      answer:
        "It includes regulatory requirements, supply chain management, inventory management, sales management, accounts and finance management, dashboards, and customized reporting.",
    },
    {
      question: "How does ClinTa MAPS handle regulatory requirements?",
      answer:
        "It is equipped with SOPs and reports required by regulatory authorities, including the handling of controlled drugs.",
    },
    {
      question: "What inventory management tools are available in it?",
      answer:
        "It offers tools for maintaining optimum stock levels, minimizing stock shrinkage, product sales classification, cyclic stock and purchase vs. sales reports, and critical reports for slow-moving products, dead items, and near-expiry stock.",
    },
    {
      question: "What sales management features does it provide?",
      answer:
        "It supports cash and credit payments, split payments, bar code reader, customer loyalty programs, and more.",
    },
    {
      question: "How does ClinTa MAPS manage accounts and finance?",
      answer:
        "It records and processes transactions, provides record-keeping, financial reporting, and integrates with other departments.",
    },

    {
      question: "Can ClinTa MAPS generate customized reports?",
      answer:
        "Absolutely, ClinTa MAPS includes a powerful reporting tool that allows you to create and customize reports based on various parameters, helping you gain valuable insights into your business operations.",
    },
    {
      question: "Does ClinTa MAPS provide alerts for inventory issues?",
      answer:
        "Yes, it provides alerts for low stock levels and near-expiry products to prevent financial losses.",
    },
    {
      question: "What advanced features are available in ClinTa MAPS?",
      answer:
        "Advanced features include product profiling, automated requisitions, patient records, doctor panel, and a specialized search engine for creative exploratory decision-making.",
    },
    {
      question: "How does ClinTa MAPS handle data security?",
      answer:
        "ClinTa MAPS employs robust data security measures including encryption, secure user authentication, and regular security updates to protect sensitive patient and business data.",
    },
    {
      question: "What kind of customer support does ClinTa MAPS offer?",
      answer:
        "ClinTa MAPS provides comprehensive customer support including 24/7 helpdesk, online resources, training sessions, and dedicated account managers to assist with any issues or queries.",
    },
    {
      question: "Is there a demo available for ClinTa MAPS?",
      answer:
        "Yes, ClinTa MAPS offers a free demo to showcase its features and capabilities. You can request a demo through our website or by contacting our sales team.",
    },
    {
      question: "How can ClinTa MAPS help improve customer satisfaction?",
      answer:
        "ClinTa MAPS enhances customer satisfaction by streamlining pharmacy operations, reducing wait times, providing accurate prescription management, and offering loyalty programs.",
    },
  ];

  return (
    <>
      <Header />
      <div className="main">
        <section className="ptb-100 bg-image overflow-hidden" image-overlay="8">
          <div
            className="background-image-wraper"
            style={{
              backgroundImage: "url('/assets/img/slider-bg-1.jpg')",
              opacity: 1,
            }}
          ></div>
          <div
            className="hero-bottom-shape-two"
            style={{
              backgroundImage: "url('/assets/img/wave-shap.svg')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
            }}
          ></div>
          <div className="effect-1 opacity-1">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 361.1 384.8"
              style="enable-background:new 0 0 361.1 384.8;"
              xml:space="preserve"
              className="injected-svg svg_img dark-color"
            >
              <path d="M53.1,266.7C19.3,178-41,79.1,41.6,50.1S287.7-59.6,293.8,77.5c6.1,137.1,137.8,238,15.6,288.9 S86.8,355.4,53.1,266.7z"></path>
            </svg> */}
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div className="col-md-12 col-lg-6">
                <div className="hero-slider-content text-white py-5">
                  <h1 className="text-white">PHARMACY Management System</h1>
                  <p className="lead">
                    It is more than just a Point of Sale (POS) and includes all
                    the segments required to ensure the stability and growth of
                    a retail pharmacy business.
                  </p>

                  {/*<div className="action-btns mt-4">*/}
                  {/*    <ul className="list-inline">*/}
                  {/*        <li className="list-inline-item">*/}
                  {/*            <a*/}
                  {/*                href="#"*/}
                  {/*                className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"*/}
                  {/*            >*/}
                  {/*                <span className="fab fa-apple icon-size-sm mr-3"></span>*/}
                  {/*                <div className="download-text text-left">*/}
                  {/*                    <small>Download form</small>*/}
                  {/*                    <h5 className="mb-0">App Store</h5>*/}
                  {/*                </div>*/}
                  {/*            </a>*/}
                  {/*        </li>*/}
                  {/*        <li className="list-inline-item">*/}
                  {/*            <a*/}
                  {/*                href="#"*/}
                  {/*                className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"*/}
                  {/*            >*/}
                  {/*                <span className="fab fa-google-play icon-size-sm mr-3"></span>*/}
                  {/*                <div className="download-text text-left">*/}
                  {/*                    <small>Download form</small>*/}
                  {/*                    <h5 className="mb-0">Google Play</h5>*/}
                  {/*                </div>*/}
                  {/*            </a>*/}
                  {/*        </li>*/}
                  {/*    </ul>*/}
                  {/*</div>*/}
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div className="img-wrap">
                  <img
                    // src="/assets/img/hero5-app.png"
                    src="/assets/img/pharmacy_managment.png"
                    alt="app image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="promo-section ptb-100 position-relative overflow-hidden">
          <div className="effect-2 opacity-1">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 463.6 616"
              style="enable-background:new 0 0 463.6 616;"
              xml:space="preserve"
              className="injected-svg svg_img dark-color"
            >
              <path d="M148.4,608.3C25.7,572.5-3.5,442.2,0.3,375.8s24.8-117,124.8-166.5s125.7-77.4,165-129.6 c43.2-57.4,96.5-94.4,127.9-73c63,43,53.9,280,14,358s-68.9,75.5-98.9,118.7S271,644,148.4,608.3z"></path>
            </svg> */}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-10">
                <div className="section-heading">
                  <h2 className="apt">
                    Complete Range of Pharmacy Software Programs
                  </h2>
                  <p>
                    We have a complete range of pharmacy software programs. We
                    offer a range of pharmacy software programs as per the
                    varying needs for different types and sizes of pharmacy
                    outlets.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4 pharmacy-single-promo-card">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-concierge-bell icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>General Pharmacy</h5>
                      <p className="mb-0">
                        It is just not the point of sale (POS) software, but
                        also covers all aspects of regulatory, inventory,
                        financial, supply chain, sales, cashflow and
                        profitability management.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4 pharmacy-single-promo-card">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-window-restore icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Hospital Pharmacy</h5>
                      <p className="mb-0">
                      In addition to all mentioned features of community pharmacy, it integrates pharmacy operations with doctors' prescriptions in all associated hospital departments.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4 pharmacy-single-promo-card">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-sync-alt icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Pharmacy Plus</h5>
                      <p className="mb-0">
                      It is an upgraded form of pharmacy software that integrates employee attendance and payroll management to streamline operations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="card border-0 single-promo-card single-promo-hover-2 p-2 mt-4 pharmacy-single-promo-card">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-bezier-curve icon-size-md color-secondary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Pharmacy ERP</h5>
                      <p className="mb-0">
                      It is a complete solution for any pharmacy outlet, providing dedicated dashboards for every department with an integrated reporting system.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="about"
          className="about-us ptb-100 position-relative gray-light-bg"
        >
          <div className="container">
            <div className="row align-items-center justify-content-lg-between justify-content-md-center">
              <div className="col-md-5 col-lg-4">
                <div className="about-content-right">
                  <img
                    // src="/assets/img/app-mobile-image-2.png"
                    src="/assets/img/detect_undetectable_pharamcy.png"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-7">
                <div className="about-content-left section-heading">
                  <h2 className="apt">Safeguard Undetectable Losses</h2>
                  <p>
                    Discover challenges of undetectable losses of pharmacy
                    operations and explore how our software solutions can help
                    you ensuring efficiency, compliance, and profitability in
                    your pharmacy operations
                  </p>
                  <ul className="check-list-wrap pt-3">
                    <li>
                      <strong>Inventory Shrinkage</strong> – It can be caused by
                      theft, pilferage, or improper inventory handling. Our
                      monitoring and controls don’t let losses go unnoticed.
                    </li>
                    <li>
                      <strong>Unrecorded Sales</strong> –It is a major
                      undetectable loss. Our accurate tracking mechanisms,
                      prevent unrecorded sales, and ultimately pharmacy revenue
                      loss.
                    </li>
                    <li>
                      <strong>Underutilization of Resources</strong> – Our
                      reporting system prevents losses from underutilized
                      resources through its monitoring and optimization
                      segments.
                    </li>
                    <li>
                      <strong>Purchasing Errors</strong> – Our integrated
                      procurement module prevents unnoticed financial losses of
                      purchasing errors, such as over & under-ordering.
                    </li>
                    <li>
                      <strong> Regulatory Non-Compliance Penalties</strong> –
                      Our proactive compliance monitoring system, prevents
                      regulatory non-compliance & its resultant penalties.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6">
                <div className="feature-contents section-heading">
                  <h2>Advanced Features Of MAPS</h2>
                  <p>
                    There are many advanced features which differentiates Clinta
                    MAPS of many other available options
                  </p>
                  <ul className="dot-circle pt-2">
                    <li>
                      Product profiling: Complete product details including
                      product sale history, product price changes over time,
                      in/out ratio etc.
                    </li>
                    <li>
                      Automated Requisitions: In this, automated requests for
                      stock are generated on the basis of user defined values,
                      system calculated values or both. In this way stock
                      ordering process became much simpler and worry-free.
                    </li>
                    <li>
                      Patient Records: It can manage each patient billing
                      history and sale records to provide better care to
                      customers.
                    </li>
                    <li>
                      Doctor Panel: It can track business generated by
                      prescribing doctors. Pharmacy user can create bills
                      according to the prescriptions generated by doctors
                      through doctors' panel.
                    </li>
                  </ul>
                  <div className="row pt-3">
                    <div className="col-4 col-lg-3 border-right">
                      <div className="count-data text-center">
                        <h4 className="count-number mb-0 color-primary font-weight-bold">
                          1023
                        </h4>
                        <span>Customers</span>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 border-right">
                      <div className="count-data text-center">
                        <h4 className="count-number mb-0 color-primary font-weight-bold">
                          5470
                        </h4>
                        <span>Downloads</span>
                      </div>
                    </div>
                    <div className="col-4 col-lg-3 border-right">
                      <div className="count-data text-center">
                        <h4 className="count-number mb-0 color-primary font-weight-bold">
                          3560
                        </h4>
                        <span>Satisfied</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="download-img">
                  <img
                    src="/assets/img/mobile-pana.svg"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="features"
          className="feature-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2 className="apt">Features of MAPS</h2>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    ClinTa MAPS offers a comprehensive suite of features
                    designed to streamline pharmacy management, ensuring
                    compliance, efficient layout management, and robust supply
                    chain oversight.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-12">
                <div className="row align-items-center">
                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-layout p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Regulatory Compliance</h5>
                        <p>
                          ClinTa MAPS includes SOPs and regulatory reports for
                          full compliance with industry standards. It has a
                          dedicated segment for handling controlled drugs.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-themify-favicon-alt p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Layout Management</h5>
                        <p>
                          Manage and track stock locations and responsibilities
                          by classifying storage positions. This ensures better
                          control over physical stock and staff duties.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-eye p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Supply Chain Management</h5>
                        <p>
                          Address inventory management, order processing, and
                          supplier coordination simultaneously for a smooth and
                          efficient supply chain.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-thumb-up p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Inventory Control</h5>
                        <p>
                          Monitor and manage inventory levels in real-time,
                          reducing wastage and ensuring essential medicines are
                          always available.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-mobile p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Sales Management</h5>
                        <p>
                          Track sales data and performance metrics to identify
                          trends and optimize sales strategies, enhancing
                          customer satisfaction.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Financial Management</h5>
                        <p>
                          Manage billing, invoicing, and cash flow to ensure
                          profitability and financial stability for the
                          pharmacy.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Access / Restrictions</h5>
                        <p>
                          A dedicated section manages access levels and
                          restrictions for different users, aligning with
                          business administrative objectives.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Customized Reporting System</h5>
                        <p>
                          Maintain and manage customer information, track
                          interactions, and improve customer service through
                          effective CRM tools.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="features-single-wrap mb-sm-0 mb-md-5 mb-lg-5">
                      <span className="ti-world p-3 mr-4 mt-1 rounded-circle float-left"></span>
                      <div className="features-single-content d-block overflow-hidden">
                        <h5 className="mb-2">Auto Alerts</h5>
                        <p>
                          Prevent stock shortages by notifying when products are
                          below threshold levels and alerting for items nearing
                          expiry.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-12">
                <img
                  src="/assets/img/app-mobile-image.png"
                  className="img-fluid mx-auto d-lg-block d-none"
                  alt="app screen"
                />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="position-relative overflow-hidden ptb-100">
                    <div className="mask-65"></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-8">
                                <div className="section-heading text-center text-white">
                                    <h2 className="text-white">The Most Uses App Platform</h2>
                                    <p>
                                        Start working with that can provide everything you need to
                                        generate awareness, drive traffic, connect. Efficiently
                                        transform granular value with client-focused content.
                                    </p>
                                </div>
                                <div className="video-promo-content my-5 pb-4">
                                    <a
                                        href="https://www.youtube.com/watch?v=9No-FiEInLA"
                                        className="popup-youtube video-play-icon text-center m-auto"
                                    >
                                        <span className="ti-control-play"></span>{" "}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-md-center justify-content-sm-center">
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                                    <div className="icon-text-wrap">
                                        <i className="fab fa-apple icon-size-md color-primary mb-2"></i>
                                        <h5>Apple Store</h5>
                                    </div>
                                    <div className="p-20px">
                                        <p className="m-0px">
                                            Seamlessly foster fully researched convergence and
                                            interactive resources administrate data.
                                        </p>
                                        <a className="btn btn-brand-02 btn-sm btn-rounded" href="#">
                                            Download Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                                    <div className="icon-text-wrap">
                                        <i className="fab fa-google-play icon-size-md color-primary mb-2"></i>
                                        <h5>Google Play</h5>
                                    </div>
                                    <div className="p-20px">
                                        <p className="m-0px">
                                            Assertively build B2C manufactured products before
                                            front-end optimize frictionless communities tailers.
                                        </p>
                                        <a className="btn btn-brand-02 btn-sm btn-rounded" href="#">
                                            Download Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                                <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                                    <div className="icon-text-wrap">
                                        <i className="fab fa-windows icon-size-md color-primary mb-2"></i>
                                        <h5>Microsoft Store</h5>
                                    </div>
                                    <div className="p-20px">
                                        <p className="m-0px">
                                            Completely engineer pandemic human capital via
                                            reconceptualize installed base niche markets.
                                        </p>
                                        <a className="btn btn-brand-02 btn-sm btn-rounded" href="#">
                                            Download Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        {/* <ScreenCarousal/> */}

        <section
          id="process"
          className="work-process-section position-relative  ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center mb-5">
                  <h2 className="apt">Advanced HR Features</h2>
                  <p>
                    ClinTa Web-Builder not only enables healthcare providers to
                    create professional websites effortlessly but also
                    integrates powerful HR features to streamline the
                    recruitment process. Enhance your hospital, clinic, lab, or
                    aesthetic center’s efficiency with our comprehensive HR
                    tools.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-md-center justify-content-sm-center">
              <div className="col-md-12 col-lg-6">
                <div className="work-process-wrap">
                  <div className="process-single-item">
                    <div className="process-icon-item left-shape">
                      <div className="d-flex align-items-center">
                        <div className="process-icon mr-4">
                          <i className="fas fa-project-diagram color-primary"></i>
                        </div>
                        <div className="process-content text-left">
                          <h5>Job Posting</h5>
                          <p>
                            Publish job openings directly on your website.
                            Customize job listings with detailed descriptions
                            and requirements.
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item right-shape">
                      <div className="d-flex align-items-center">
                        <div className="process-icon ml-4">
                          <i className="fas fa-puzzle-piece color-primary"></i>
                        </div>
                        <div className="process-content text-right">
                          <h5>CV Management</h5>
                          <p>
                            Receive applications directly within the admin
                            panel. Filter and organize CVs based on specific
                            criteria of department for which CV is submitted,
                            qualification, experience, gender and age group.
                            View CVs separately for interviewed and
                            non-interviewed candidates.
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item left-shape">
                      <div className="d-flex align-items-center">
                        <div className="process-icon mr-4">
                          <i className="fas fa-truck color-primary"></i>
                        </div>
                        <div className="process-content text-left">
                          <h5>Interview Scheduling</h5>
                          <p>
                            Schedule interviews seamlessly through the admin
                            panel. Manage interview timings and notify
                            candidates directly from the website.
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="process-single-item">
                    <div className="process-icon-item right-shape">
                      <div className="d-flex align-items-center">
                        <div className="process-icon ml-4">
                          <i className="fas fa-puzzle-piece color-primary"></i>
                        </div>
                        <div className="process-content text-right">
                          <h5>Candidate Categorization</h5>
                          <p>
                            Mark interviewed candidates into four categories: A,
                            B, C and D. Use the categorization for efficient
                            long listing and shortlisting.
                          </p>
                        </div>
                      </div>
                      <svg x="0px" y="0px" width="312px" height="130px">
                        <path
                          className="dashed1"
                          fill="none"
                          stroke="rgb(95, 93, 93)"
                          stroke-width="1"
                          stroke-dasharray="1300"
                          stroke-dashoffset="0"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                        <path
                          className="dashed2"
                          fill="none"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-dasharray="6"
                          stroke-dashoffset="1300"
                          d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <div className="process-single-item">
                    <div className="process-icon-item left-shape mb-0">
                      <div className="d-flex align-items-center">
                        <div className="process-icon mr-4">
                          <i className="fas fa-truck color-primary"></i>
                        </div>
                        <div className="process-content text-left">
                          <h5>Candidate Remarks and Finalization</h5>
                          <p>
                            Add remarks and notes for each candidate for future
                            reference. Communicate decisions to candidates
                            through auto email system or website’s integrated
                            messaging system.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="img-wrap">
                  <img
                    src="/assets/img/app-mobile-image-3.png"
                    alt="features"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <PricingTableSecond product="pharmacy"/> */}

        {/* <Testimonial/> */}

        {/* <Faqs/> */}

        {/* <section className="our-blog-section ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-8">
                                <div className="section-heading text-center">
                                    <h2>Our Latest News</h2>
                                    <p>
                                        Efficiently matrix robust total linkage after market
                                        positioning bandwidth. Holisticly restore B2B materials
                                        rather than brand flexible paradigms vis-a-vis
                                        mission-critical e-commerce.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                <div className="single-blog-card card border-0 shadow-sm mt-4">
                                    <div className="blog-img position-relative">
                                        <img
                                            src="/assets/img/blog/1.jpg"
                                            className="card-img-top"
                                            alt="blog"
                                        />
                                        <div className="meta-date">
                                            <strong>24</strong>
                                            <small>Apr</small>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="post-meta mb-2">
                                            <ul className="list-inline meta-list">
                                                <li className="list-inline-item">
                                                    <i className="fas fa-heart mr-2"></i>
                                                    <span>45</span>
                                                    Comments
                                                </li>
                                                <li className="list-inline-item">
                                                    <i className="fas fa-share-alt mr-2"></i>
                                                    <span>10</span>
                                                    Share
                                                </li>
                                            </ul>
                                        </div>
                                        <h3 className="h5 mb-2 card-title">
                                            <a href="#">Appropriately productize fully</a>
                                        </h3>
                                        <p className="card-text">
                                            Some quick example text to build on the card title and
                                            make up the bulk.
                                        </p>
                                        <a href="#" className="detail-link">
                                            Read more <span className="ti-arrow-right"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="single-blog-card card border-0 shadow-sm mt-4">
                                    <div className="blog-img position-relative">
                                        <img
                                            src="/assets/img/blog/2.jpg"
                                            className="card-img-top"
                                            alt="blog"
                                        />
                                        <div className="meta-date">
                                            <strong>24</strong>
                                            <small>Apr</small>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="post-meta mb-2">
                                            <ul className="list-inline meta-list">
                                                <li className="list-inline-item">
                                                    <i className="fas fa-heart mr-2"></i>
                                                    <span>45</span>
                                                    Comments
                                                </li>
                                                <li className="list-inline-item">
                                                    <i className="fas fa-share-alt mr-2"></i>
                                                    <span>10</span>
                                                    Share
                                                </li>
                                            </ul>
                                        </div>
                                        <h3 className="h5 mb-2 card-title">
                                            <a href="#">Quickly formulate backend</a>
                                        </h3>
                                        <p className="card-text">
                                            Synergistically engage effective ROI after customer
                                            directed partnerships.
                                        </p>
                                        <a href="#" className="detail-link">
                                            Read more <span className="ti-arrow-right"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="single-blog-card card border-0 shadow-sm mt-4">
                                    <div className="blog-img position-relative">
                                        <img
                                            src="/assets/img/blog/3.jpg"
                                            className="card-img-top"
                                            alt="blog"
                                        />
                                        <div className="meta-date">
                                            <strong>24</strong>
                                            <small>Apr</small>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="post-meta mb-2">
                                            <ul className="list-inline meta-list">
                                                <li className="list-inline-item">
                                                    <i className="fas fa-heart mr-2"></i>
                                                    <span>45</span>
                                                    Comments
                                                </li>
                                                <li className="list-inline-item">
                                                    <i className="fas fa-share-alt mr-2"></i>
                                                    <span>10</span>
                                                    Share
                                                </li>
                                            </ul>
                                        </div>
                                        <h3 className="h5 mb-2 card-title">
                                            <a href="#">Objectively extend extensive</a>
                                        </h3>
                                        <p className="card-text">
                                            Holisticly mesh open-source leadership rather than
                                            proactive users.{" "}
                                        </p>
                                        <a href="#" className="detail-link">
                                            Read more <span className="ti-arrow-right"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
        {/* <section id="contact" className="contact-us-section ptb-100">
                    <div className="container">
                        <div className="row justify-content-around">
                            <div className="col-12 pb-3 message-box d-none">
                                <div className="alert alert-danger"></div>
                            </div>
                            <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                                <div className="contact-us-form gray-light-bg rounded p-5">
                                    <h4>Ready to get started?</h4>
                                    <form
                                        action="#"
                                        method="POST"
                                        id="contactForm"
                                        className="contact-us-form"
                                    >
                                        <div className="form-row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        placeholder="Enter name"
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        placeholder="Enter email"
                                                        required="required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                          <textarea
                              name="message"
                              id="message"
                              className="form-control"
                              rows="7"
                              cols="25"
                              placeholder="Message"
                          ></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-brand-02"
                                                    id="btnContactUs"
                                                >
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="contact-us-content">
                                    <h2>Looking for a excellent Business idea?</h2>
                                    <p className="lead">
                                        Give us a call or drop by anytime, we endeavour to answer
                                        all enquiries within 24 hours on business days.
                                    </p>

                                    <a
                                        href="#"
                                        className="btn btn-outline-brand-01 align-items-center"
                                    >
                                        Get Directions <span className="ti-arrow-right pl-2"></span>
                                    </a>

                                    <hr className="my-5"/>

                                    <ul className="contact-info-list">
                                        <li className="d-flex pb-3">
                                            <div className="contact-icon mr-3">
                                                <span
                                                    className="fas fa-location-arrow color-primary rounded-circle p-3"></span>
                                            </div>
                                            <div className="contact-text">
                                                <h5 className="mb-1">Company Location</h5>
                                                <p>100 Yellow House, Mn Factory, United State, 13420</p>
                                            </div>
                                        </li>
                                        <li className="d-flex pb-3">
                                            <div className="contact-icon mr-3">
                                                <span
                                                    className="fas fa-envelope color-primary rounded-circle p-3"></span>
                                            </div>
                                            <div className="contact-text">
                                                <h5 className="mb-1">Email Address</h5>
                                                <p>hello@yourdomain.com</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </div>

      <section id="faq" className="ptb-100 white-light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="section-heading text-center mb-5">
                <h2 className="web">Frequently Asked Queries</h2>
                <p>
                  Efficiently productivate reliable paradigms before ubiquitous
                  models. Continually utilize frictionless expertise whereas
                  tactical relationships. Still have questions? Contact us
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                {faqData.map((item, index) => (
                  <div className="col-md-6">
                    <div id="accordion" className="accordion faq-wrap">
                      <div className="card my-3" key={index}>
                        <div
                          className={`card-header ${
                            activeAccordion === index
                              ? "web-color"
                              : "collapsed"
                          }`}
                          onClick={() => toggleAccordion(index)}
                        >
                          <h6 className="mb-0 d-inline-block webbuilder-faq-title">
                            {item.question}
                          </h6>
                        </div>
                        <div
                          className={`collapse ${
                            activeAccordion === index ? "show" : ""
                          }`}
                          aria-labelledby={`heading${index}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body white-bg">
                            <p>{item.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Newsletter/> */}
      <Footer />
    </>
  );
}

export default PharmacySoftware;
