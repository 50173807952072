import React, { useEffect, useRef, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { SendPostRequest } from "../http";
import { toast } from "react-toastify";

function ContactUs() {
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const contactNumberInputRef = useRef(null);
  const [error, setError] = useState(null);

  var BreadCrumbTitle = "Contact Us :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;
    let error = null;
    if (name === "contact_number") {
      updatedValue = value.slice(0, 11);
      setError(updatedValue.length < 11 ? "Invalid Contact Number" : null);
    }
    setMessage((prv) => ({ ...prv, [name]: value }));
  };

  const validate = () => {
    const validNum = message.contact_number.length === 11 ? true : false;
    !validNum && contactNumberInputRef.current.focus();
    setError(validNum ? null : "Invalid Contact Number");

    return validNum;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;

    setLoading(true);
    try {
      const response = await SendPostRequest("contact-us", {
        form_data: message,
      });
      console.log("Message response", response);
      if (response?.status === "success") {
        setMessage({});
        toast.success(response?.message);
        // alert(response?.message);
      } else {
        toast.error(response?.message);
        // alert(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="main">
        <section
          className="page-header-section ptb-100 bg-image"
          image-overlay="8"
        >
          <div
            className="background-image-wraper"
            style={{
              background: `url('/assets/img/contactus-banner.jpg')`,
              // background: `url('/assets/img/slider-bg-1.jpg')`,
              opacity: "1",
            }}
          ></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white pt-4">
                  <h1 className="text-white mb-0">Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="breadcrumb-bar gray-light-bg border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="custom-breadcrumb">
                  <ol className="breadcrumb pl-0 mb-0 bg-transparent">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    {/*<li className="breadcrumb-item"><a href="#">Pages</a></li>*/}
                    <li className="breadcrumb-item active">Contact us</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="promo-section pt-100 ">
          <div className="container">
            <div className="row justify-content-md-center justify-content-sm-center">
              <div className="col-md-6 col-lg-4">
                <div className="card single-promo-card shadow-sm text-center p-3 my-3 contact_cards_wrapper">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-envelope icon-size-lg color-primary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Mail Us</h5>
                      <a className="email-link" href="mailto:info@dright.net">info@dright.net</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card single-promo-card shadow-sm text-center p-3 my-3 contact_cards_wrapper">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-headset icon-size-lg color-primary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>24/7 Live Chat</h5>
                      <p className="mb-0">
                        We endeavour to answer all enquiries within 24 hours on
                        business days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card single-promo-card shadow-sm text-center p-3 my-3 contact_cards_wrapper">
                  <div className="card-body">
                    <div className="pb-2">
                      <span className="fas fa-map-marker-alt icon-size-lg color-primary"></span>
                    </div>
                    <div className="pt-2 pb-3">
                      <h5>Visit Us</h5>
                      <p className="mb-0">
                      131 Civic Center – Bahria Town Phase 4, Islamabad
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact-us-section ptb-100">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-12 pb-3 message-box d-none">
                <div className="alert alert-danger"></div>
              </div>
              <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                <div className="contact-us-form gray-light-bg rounded p-5">
                  <h4>Ready to get started?</h4>
                  <form
                    onSubmit={handleSubmit}
                    id="contactForm"
                    className="contact-us-form"
                  >
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter Your Name"
                            required
                            onChange={handleChange}
                            value={message?.name || ""}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="number"
                            className={`form-control ${
                              error && "border-danger"
                            }`}
                            name="contact_number"
                            placeholder="Enter Contact Number"
                            required
                            onChange={handleChange}
                            value={message?.contact_number || ""}
                            ref={contactNumberInputRef}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter Your Email"
                            required
                            onChange={handleChange}
                            value={message?.email || ""}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="7"
                            cols="25"
                            placeholder="Message"
                            required
                            onChange={handleChange}
                            value={message?.message || ""}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-3">
                        <button
                          type="submit"
                          className="btn btn-brand-02"
                          id="btnContactUs"
                          disabled={loading}
                        >
                          {loading ? "Sending..." : "Send Message"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="contact-us-content">
                  <h2>Looking for Advanced Medical Software?</h2>
                  <p className="lead">
                    Contact us today to explore our extensive range of medical
                    solutions tailored specifically to your needs.
                  </p>

                  {/* <a
                    href="#"
                    className="btn btn-outline-brand-01 align-items-center"
                  >
                    Get Directions <span className="ti-arrow-right pl-2"></span>
                  </a> */}

                  <hr className="my-5" />

                  <ul className="contact-info-list">
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-location-arrow color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Company Location</h5>
                        <p>131 Civic Center – Bahria Town Phase 4, Islamabad</p>
                      </div>
                    </li>
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-envelope color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Email Address</h5>
                  
                        <a className="email-link" href="mailto:info@dright.net">info@dright.net</a>
                      

                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.118672522288!2d73.11908368244!3d33.550292875370666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfed04d81818a1%3A0xecfa8857f30563f7!2sCivic%20Center%20Bahria%20Town%20Civic%20Center%20Bahria%20Town%2C%20Rawalpindi%2C%20Islamabad%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1721368323831!5m2!1sen!2s"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
          ></iframe>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
