import React, {useEffect} from 'react'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

function Gallery() {
    var BreadCrumbTitle = "Gallery :: D-Right Technologies";
    document.title = BreadCrumbTitle;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const GalleryData = [
        {
            cover: "/assets/img/blog/1.jpg",
            title: "Gallery A",
            desc: "Lorem Ipsum is Lorem Ipsum and it is used in the Lorem Ipsum text"
        },
        {
            cover: "/assets/img/blog/2.jpg",
            title: "Gallery B",
            desc: "Lorem Ipsum is Lorem Ipsum and it is used in the Lorem Ipsum text"
        },
        {
            cover: "/assets/img/blog/3.jpg",
            title: "Gallery C",
            desc: "Lorem Ipsum is Lorem Ipsum and it is used in the Lorem Ipsum text"
        },
    ];

    return (
        <>
            <Header/>
            <div className="main">
                <section className="page-header-section ptb-100 bg-image" image-overlay="8">
                    <div className="background-image-wraper"
                         style={{background: `url('assets/img/slider-bg-1.jpg')`, opacity: "1"}}></div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-9 col-lg-7">
                                <div className="page-header-content text-white pt-4">
                                    <h1 className="text-white mb-0">Gallery</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="breadcrumb-bar gray-light-bg border-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="custom-breadcrumb">
                                    <ol className="breadcrumb pl-0 mb-0 bg-transparent">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Gallery</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="our-blog-section ptb-100">
                    <div className="container">
                        <div className="row">
                            {GalleryData.map((item, index) => (
                                <div className="col-md-6 col-lg-4" key={index}>
                                    <div className="single-blog-card card gray-light-bg border-0 shadow-sm my-3">
                                        <div className="blog-img position-relative">
                                            <img src={item.cover} className="card-img-top" alt="blog"/>
                                        </div>
                                        <div className="card-body">
                                            <h3 className="h5 mb-2 card-title"><a href="#">{item.title}</a></h3>
                                            <p className="card-text">{item.desc}</p>
                                            <a href="#" className="detail-link">
                                                Read more <span className="ti-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </>
    )
}

export default Gallery
