import React, { useEffect } from "react";
import "./Main.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CounterSection from "../HomeComponents/CounterSection";
import WhyChoose from "../HomeComponents/WhyChoose";
import WorkProcess from "../HomeComponents/WorkProcess";
import TeamMembers from "../Components/TeamMembers";
import About2 from "../HomeComponents/About2";
import HeroSection2 from "../HomeComponents/HeroSection2";
import Features from "../HomeComponents/Features";
import ScreenCarousal from "../HomeComponents/ScreenCarousal";
import PriceSection from "../HomeComponents/PriceSection";
import Faqs from "../HomeComponents/Faqs";
import Testimonial from "../HomeComponents/Testimonial";
import Blogs from "../HomeComponents/Blogs";
import ContactUs2 from "../HomeComponents/ContactUs2";
import AppPlatform from "../HomeComponents/AppPlatform";
import HeroSlider from "../HomeComponents/HeroSlider";
import PricingTableSecond from "../Components/PricingTableSecond";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {/* <HomeHeroSection /> */}
      {/* <HeroSection2 /> */}
      <HeroSlider />

      <WhyChoose />

      <About2 />

      <Features />
      {/*<AppPlatform />*/}

      {/*<ScreenCarousal />*/}
      <WorkProcess />

      {/* <PriceSection /> */}
      {/* <PricingTableSecond /> */}
      {/*<CounterSection />*/}

      <Faqs />

      {/*<TeamMembers />*/}
      {/*<Testimonial />*/}
      {/*<Blogs />*/}

      {/*<ContactUs2 />*/}

      {/*<Newsletter />*/}
      <Footer />
    </>
  );
}

export default Home;
