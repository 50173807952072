import React from 'react';

function WorkProcess() {
    return (
        <div>

            {/* <section id="process" className="work-process-section position-relative ptb-100 gray-light-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-8">
                            <div className="section-heading text-center mb-5">
                                <h2>Quality Control Process</h2>
                                <p>At D-Right Technologies (Pvt.) Ltd, quality is at the core of everything we do. We
                                    follow a meticulous quality control process to ensure that our healthcare software
                                    solutions meet the highest standards of excellence. From initial planning to
                                    continuous monitoring and improvement, our quality control process encompasses
                                    following six key steps that guide our commitment to delivering reliable, efficient,
                                    and secure software products.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-md-center justify-content-sm-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="work-process-wrap">
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon mr-4">
                                                <i className="fas fa-project-diagram color-primary"></i>
                                            </div>
                                            <div className="process-content text-left">
                                                <h5>1.	Quality Planning: </h5>
                                                <p>Establish quality objectives, criteria, and processes for meeting standards.</p>
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1"
                                                  stroke-dasharray="1300" stroke-dashoffset="0"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"></path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                                  stroke-dasharray="6" stroke-dashoffset="1300"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item right-shape">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon ml-4">
                                                <i className="fas fa-puzzle-piece color-primary"></i>
                                            </div>
                                            <div className="process-content text-right">
                                                <h5>2.	Quality Assurance: </h5>
                                                <p>Systematic activities to ensure adherence to quality standards throughout development.</p>
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1"
                                                  stroke-dasharray="1300" stroke-dashoffset="0"
                                                  d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                                  stroke-dasharray="6" stroke-dashoffset="1300"
                                                  d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape mb-0">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon mr-4">
                                                <i className="fas fa-truck color-primary"></i>
                                            </div>
                                            <div className="process-content text-left">
                                                <h5>3.	Quality Control: </h5>
                                                <p> Verify software meets quality requirements through testing and validation.</p>
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)"
                                                  stroke-width="1"
                                                  stroke-dasharray="1300" stroke-dashoffset="0"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"></path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                                  stroke-dasharray="6" stroke-dashoffset="1300"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item right-shape mt-5">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon ml-4">
                                                <i className="fas fa-puzzle-piece color-primary"></i>
                                            </div>
                                            <div className="process-content text-right ">
                                                <h5>4.	Continuous Monitoring: </h5>
                                                <p>Ongoing surveillance to maintain consistent quality standards.</p>
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)"
                                                  stroke-width="1"
                                                  stroke-dasharray="1300" stroke-dashoffset="0"
                                                  d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                                  stroke-dasharray="6" stroke-dashoffset="1300"
                                                  d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape mb-0">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon mr-4">
                                                <i className="fas fa-truck color-primary"></i>
                                            </div>
                                            <div className="process-content text-left">
                                                <h5>5.	Feedback and Improvement: </h5>
                                                <p> Solicit feedback and implement improvements for enhanced quality.</p>
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)"
                                                  stroke-width="1"
                                                  stroke-dasharray="1300" stroke-dashoffset="0"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"></path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                                  stroke-dasharray="6" stroke-dashoffset="1300"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item right-shape mt-5">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon ml-4">
                                                <i className="fas fa-puzzle-piece color-primary"></i>
                                            </div>
                                            <div className="process-content text-right ">
                                                <h5>6.	Documentation and Reporting:</h5>
                                                <p>Maintain records and report on quality-related activities for transparency and accountability</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="img-wrap">
                                <img src="/assets/img/app-mobile-image-3.png" alt="features" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
               <section id="process" className="work-process-section position-relative ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-12 col-lg-6">
                                <div className="feature-contents section-heading">
                                    <h2>Quality Control Process</h2>
                                    <p>
                                    At D-Right Technologies (Pvt.) Ltd, quality is at the core of everything we do. We
                                    follow a meticulous quality control process to ensure that our healthcare software
                                    solutions meet the highest standards of excellence. From initial planning to
                                    continuous monitoring and improvement, our quality control process encompasses
                                    following six key steps that guide our commitment to delivering reliable, efficient,
                                    and secure software products.
                                    </p>
                                    {/* <p>
                                        Uniquely simplify sustainable applications whereas adaptive
                                        schemas. Competently brand performance based content and.
                                    </p> */}
                                    <ul className="dot-circle pt-2">
                                        <li>Quality Planning.</li>
                                        <li>Quality Assurance.</li>
                                        <li>Quality Control.</li>
                                        <li>Continuous Monitoring.</li>
                                        <li>
                                        Feedback and Improvement
                                        </li>
                                        {/* <li>Documentation and Reporting.</li> */}
                                    </ul>
                                    {/* <div className="row pt-3">
                                        <div className="col-4 col-lg-3 border-right">
                                            <div className="count-data text-center">
                                                <h4 className="count-number mb-0 color-primary font-weight-bold">
                                                    1023
                                                </h4>
                                                <span>Customers</span>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-4 col-lg-3 border-right">
                                            <div className="count-data text-center">
                                                <h4 className="count-number mb-0 color-primary font-weight-bold">
                                                    5470
                                                </h4>
                                                <span>Downloads</span>
                                            </div>
                                        </div>
                                        <div className="col-4 col-lg-3 border-right">
                                            <div className="count-data text-center">
                                                <h4 className="count-number mb-0 color-primary font-weight-bold">
                                                    3560
                                                </h4>
                                                <span>Satisfied</span>
                                            </div>
                                        </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="download-img">
                                    <img
                                        src="/assets/img/5succsessstep.png"
                                        alt="download"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </div>
    )
}

export default WorkProcess;
