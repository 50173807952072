import React, { useEffect } from "react";
import Header from "../Components/Header";
import "../Pages/Main.css";
import Footer from "../Components/Footer";
import Newsletter from "../Components/Newsletter";
import Counter from "../LabSoftware/Counter";
import Faqs from "../LabSoftware/Faqs";
import Testimonial from "../LabSoftware/Testimonial";
import WorkProcess from "../LabSoftware/WorkProcess";
import Features from "../LabSoftware/Features";
import LatestBlogs from "../LabSoftware/LatestBlogs";
import PricingTableSecond from "../Components/PricingTableSecond";
import LarisBannerSlider from "../LabSoftware/LarisBannerSlider";

function LabSoftware() {
  var BreadCrumbTitle = "LARIS :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="main">
        {/* 
      <section className="ptb-80 hero-bg-shape laris_bg-wrapper" style={{backgroundImage: "url('/assets/img/hero-bg-shape-1.svg')"}}>
            
        <LarisBannerSlider />
        </section> */}

        <LarisBannerSlider />
        {/* <section
          className="position-relative bg-image pt-100"
          image-overlay="8"
        >
          <div
            className="background-image-wraper"
            style={{
              background: `url('/assets/img/hero-bg-shape-1.svg')`,
              opacity: 1,
            }}
          ></div>

          <div className="shape-bottom">
            <img
              src="assets/img/curve-shape-top.svg"
              alt="shape"
              className="bottom-shape img-fluid"
            />
          </div>
        </section> */}

        {/*<Features/>*/}

        <section
          className="bg-image ptb-100"
          image-overlay="8"
          style={{ marginTop: "100px" }}
        >
          <div
            className="background-image-wraper"
            style={{
              background: ` url('/assets/img/cta-bg.jpg')no-repeat center center / cover fixed`,
              opacity: "1",
            }}
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-10">
                <div className="section-heading text-center mb-1 text-white">
                  <h2 className="text-white ">
                    LAB ADVANCEMENTS through RELIABLE & INNOVATIVE SOFTWARE
                    (LARIS)
                  </h2>
                  <p>
                    Introducing LARIS, the cutting-edge pathology laboratory
                    software meticulously crafted to streamline workflow and
                    optimize efficiency. Launched in Pakistan by D-Right
                    Technologies (Pvt.) Ltd. in collaboration with ClinTa
                    Healthcare Technologies (CHT), USA. It caters to the
                    diverse needs of phlebotomists, technologists, pathologists,
                    and administrators, providing tailored solutions for
                    seamless operation and enhanced productivity in pathology
                    labs across Pakistan.
                  </p>
                  {/*<div className="action-btns">*/}
                  {/*    <ul className="list-inline">*/}
                  {/*        <li className="list-inline-item my-2">*/}
                  {/*            <a href="#"*/}
                  {/*               className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded">*/}
                  {/*                <span className="fab fa-windows icon-size-sm mr-3"></span>*/}
                  {/*                <div className="download-text text-left">*/}
                  {/*                    <small>Download form</small>*/}
                  {/*                    <h5 className="mb-0">Windows</h5>*/}
                  {/*                </div>*/}
                  {/*            </a>*/}
                  {/*        </li>*/}
                  {/*        <li className="list-inline-item my-2">*/}
                  {/*            <a href="#"*/}
                  {/*               className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded">*/}
                  {/*                <span className="fab fa-apple icon-size-sm mr-3"></span>*/}
                  {/*                <div className="download-text text-left">*/}
                  {/*                    <small>Download form</small>*/}
                  {/*                    <h5 className="mb-0">App Store</h5>*/}
                  {/*                </div>*/}
                  {/*            </a>*/}
                  {/*        </li>*/}
                  {/*        <li className="list-inline-item my-2">*/}
                  {/*            <a href="#"*/}
                  {/*               className="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded">*/}
                  {/*                <span className="fab fa-google-play icon-size-sm mr-3"></span>*/}
                  {/*                <div className="download-text text-left">*/}
                  {/*                    <small>Download form</small>*/}
                  {/*                    <h5 className="mb-0">Google Play</h5>*/}
                  {/*                </div>*/}
                  {/*            </a>*/}
                  {/*        </li>*/}
                  {/*    </ul>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        <WorkProcess />

        {/* <PriceTable/> */}

        <LatestBlogs />

        {/* <PricingTableSecond product="lab" /> */}

        {/* <Counter /> */}

        {/* <section className="team-two-section ptb-100 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center">
                  <h2>Our Team Members</h2>
                  <p>
                    Authoritatively mesh intuitive paradigms vis-a-vis
                    goal-oriented partnerships. Continually extend open-source
                    outside the box thinking after focused catalysts.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-1.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Richard Ford</h5>
                    <span>Instructor of Mathematics</span>
                    <p className="mt-3">
                      Authoritatively engage leading-edge processes tactical
                      capital{" "}
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-2.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Kely Roy</h5>
                    <span>Lead Designer</span>
                    <p className="mt-3">
                      Monotonectally engage sticky collaborative markets
                      synergistically
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-3.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Managing Director</span>
                    <p className="mt-3">
                      Assertively procrastinate standardized whereas technically
                      sound
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">
                  <img
                    src="/assets/img/team/team-member-4.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Team Manager</span>
                    <p className="mt-3">
                      Synergistically actualize out the-box technologies before
                      parallel process
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <Testimonial /> */}

        {/* <section id="contact" className="contact-us-section ptb-100">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-12 pb-3 message-box d-none">
                <div className="alert alert-danger"></div>
              </div>
              <div className="col-md-12 col-lg-5 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
                <div className="contact-us-form gray-light-bg rounded p-5">
                  <h4>Ready to get started?</h4>
                  <form
                    action="#"
                    method="POST"
                    id="contactForm"
                    className="contact-us-form"
                  >
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Enter name"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter email"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="7"
                            cols="25"
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-3">
                        <button
                          type="submit"
                          className="btn btn-brand-02"
                          id="btnContactUs"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="contact-us-content">
                  <h2>Looking for a excellent Business idea?</h2>
                  <p className="lead">
                    Give us a call or drop by anytime, we endeavour to answer
                    all enquiries within 24 hours on business days.
                  </p>

                  <a
                    href="#"
                    className="btn btn-outline-brand-01 align-items-center"
                  >
                    Get Directions <span className="ti-arrow-right pl-2"></span>
                  </a>

                  <hr className="my-5" />

                  <ul className="contact-info-list">
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-location-arrow color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Company Location</h5>
                        <p>100 Yellow House, Mn Factory, United State, 13420</p>
                      </div>
                    </li>
                    <li className="d-flex pb-3">
                      <div className="contact-icon mr-3">
                        <span className="fas fa-envelope color-primary rounded-circle p-3"></span>
                      </div>
                      <div className="contact-text">
                        <h5 className="mb-1">Email Address</h5>
                        <p>hello@yourdomain.com</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      <Faqs />
      <Newsletter />
      <Footer />
    </>
  );
}

export default LabSoftware;
