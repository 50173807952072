import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [specialRoute, setSpecialRoute] = useState(false);

  const location = useLocation();
  useEffect(() => {
    // Determine if the current route is special
    if (
      // location.pathname === "/laris" ||
      location.pathname === "/pipeline-product"
      // location.pathname === '/pharmacy-software' ||
      // location.pathname === "/web-builder"
    ) {
      setSpecialRoute(true);
    } else {
      setSpecialRoute(false);
    }
  }, []);
  console.log("path", specialRoute);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      <header
        className={`header ${isScrolled ? "scrolled" : ""} ${
          specialRoute ? "scrolled-2" : ""
        }`}
      >
        {/* Navbar */}
        <nav
          className={`navbar navbar-expand-lg fixed-top custom-nav ${
            isScrolled ? "scrolled" : ""
          }`}
        >
          <div className="container p-0">
            <Link className="navbar-brand" to="/">
              <img src="/assets/logo.png" alt="logo" className="img-fluid" />
            </Link>
            <RxHamburgerMenu className="hamburger" onClick={handleMenuClick} />
            <div
              className={`collapse navbar-collapse h-auto ${
                isMenuOpen ? "show" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto menu">
                <li>
                  <Link to="/" className="page-scroll">
                    Home
                  </Link>
                </li>
                <li>
                  <Link href="#" className="dropdown-toggle">
                    About Us
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/vision-mission">Vision & Mission</Link>
                    </li>
                    <li>
                      <Link to="/about-founder">About the Founder</Link>
                    </li>
                    <li>
                      <Link to="/founder-message">Founder’s Message</Link>
                    </li>
                    <li>
                      <Link to="/teams">Team</Link>
                    </li>
                  </ul>
                </li>

                {/*<li>*/}
                {/*  <Link href="#" className="dropdown-toggle">Team</Link>*/}
                {/*  <ul className="sub-menu">*/}
                {/*    */}
                {/*  </ul>*/}
                {/*</li>*/}

                <li>
                  <Link href="#" className="dropdown-toggle">
                    Portfolio
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/web-builder">Web Builder</Link>
                    </li>
                    <li>
                      <Link to="/laris">LARIS</Link>
                    </li>
                    <li>
                      <Link to="/maps">MAPS</Link>
                    </li>

                    <li>
                      <Link to="/pipeline-product">Quest</Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
                  <Link href="#" className="dropdown-toggle">
                    Resource Hub
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/pathology-lab">For Pathology Lab</Link>
                    </li>
                    <li>
                      <Link to="/pharmacy-outlets">For Pharmacy Outlets</Link>
                    </li>
                    <li>
                      <Link to="/for-doctors">For Doctors</Link>
                    </li>
                  </ul>
                </li> */}

                {/* <li>
                  <Link to="/gallery" className="page-scroll">
                    Gallery
                  </Link>
                </li>
                <li>
                  <Link to="/endorsements" className="page-scroll">
                    Endorsements
                  </Link>
                </li> */}
                <li>
                  <Link to="/contact-us" className="page-scroll">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default Header;
