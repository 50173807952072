import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { SendPostRequest } from "../http";
import Spinner from "../Components/Spinner";

function LeaderShip() {
  var BreadCrumbTitle = "Team :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
    getTeamData();
  }, []);

  const [teamData, setTeamData] = useState([]);
  console.log("teamData", teamData);
  const getTeamData = async () => {
    const response = await SendPostRequest("team");
    setTeamData(response?.data);
  };

  return (
    <>
      <Header />

      <div className="main">
        <section
          className="page-header-section ptb-100 bg-image"
          image-overlay="7"
        >
          <div
            className="background-image-wraper"
            style={{
              // background: `url('assets/img/slider-bg-1.jpg')`,
              background: `url('assets/img/team-banner.jpg')`,
              opacity: "1",
            }}
          ></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white pt-4">
                  <h1 className="text-white mb-0">Team</h1>
                  {/*<p className="lead">Rapidiously deploy world-class platforms whereas collaborative interfaces. Phosfluorescently facilitate corporate innovation via excellent web <services></services> technically sound. </p>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="breadcrumb-bar gray-light-bg border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="custom-breadcrumb">
                  <ol className="breadcrumb pl-0 mb-0 bg-transparent">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    {/*<li className="breadcrumb-item">Teams</li>*/}
                    <li className="breadcrumb-item active">Team</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="team-two-section ptb-100 ">
          <div className="container">
            {/* <h1 className="h1 text-black mb-0">Leadership Team</h1>
            <p className="p">
              This segment includes me and my close associates who contributed a
              lot even before launch of the project
            </p>
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="single-team-wrap">
                  <div className="row no-gutters shadow-sm position-relative rounded-custom team-content-wrap bg-white ">
                    <div className="col-sm-5 col-md-5 col-lg-6 col-12 order-0 d-none d-sm-block d-md-block d-lg-block">
                      <div className="team-img position-absolute">
                        <img
                          src="assets/img/team/7.png"
                          alt="team"
                          className="img-fluid rounded-custom"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 col-md-7 col-lg-6 col-12 order-1">
                      <div className="single-team-info py-4 px-5 px-md-4">
                        <div className="name-designation mb-3">
                          <h5 className="mb-0">Liam Kaiya</h5>
                          <span className="color-secondary">Web Designer</span>
                        </div>
                        <p>Distinctively synthesize equity invested.</p>
                        <ul className="list-inline social-list-default social-hover-2">
                          <li className="list-inline-item">
                            <a className="facebook" href="#" target="_blank">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="twitter" href="#" target="_blank">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="dribbble" href="#" target="_blank">
                              <i className="fab fa-dribbble"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="linkedin" href="#" target="_blank">
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="single-team-wrap">
                  <div className="row no-gutters shadow-sm position-relative rounded-custom team-content-wrap bg-white ">
                    <div className="col-sm-5 col-md-5 col-lg-6 col-12 order-0 d-none d-sm-block d-md-block d-lg-block">
                      <div className="team-img position-absolute">
                        <img
                          src="assets/img/team/8.png"
                          alt="team"
                          className="img-fluid rounded-custom"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 col-md-7 col-lg-6 col-12 order-1">
                      <div className="single-team-info py-4 px-5 px-md-4">
                        <div className="name-designation mb-3">
                          <h5 className="mb-0">Kaiya Kabun</h5>
                          <span className="color-secondary">Team Leader</span>
                        </div>
                        <p>Distinctively synthesize equity invested.</p>
                        <ul className="list-inline social-list-default social-hover-2">
                          <li className="list-inline-item">
                            <a className="facebook" href="#" target="_blank">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="twitter" href="#" target="_blank">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="dribbble" href="#" target="_blank">
                              <i className="fab fa-dribbble"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="linkedin" href="#" target="_blank">
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="single-team-wrap">
                  <div className="row no-gutters shadow-sm position-relative rounded-custom team-content-wrap bg-white ">
                    <div className="col-sm-5 col-md-5 col-lg-6 col-12 order-0 d-none d-sm-block d-md-block d-lg-block">
                      <div className="team-img position-absolute">
                        <img
                          src="assets/img/team/9.png"
                          alt="team"
                          className="img-fluid rounded-custom"
                        />
                      </div>
                    </div>
                    <div className="col-sm-7 col-md-7 col-lg-6 col-12 order-1">
                      <div className="single-team-info py-4 px-5 px-md-4">
                        <div className="name-designation mb-3">
                          <h5 className="mb-0">Resard Joe</h5>
                          <span className="color-secondary">UX Designer</span>
                        </div>
                        <p>Distinctively synthesize equity invested.</p>
                        <ul className="list-inline social-list-default social-hover-2">
                          <li className="list-inline-item">
                            <a className="facebook" href="#" target="_blank">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="twitter" href="#" target="_blank">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="dribbble" href="#" target="_blank">
                              <i className="fab fa-dribbble"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a className="linkedin" href="#" target="_blank">
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <h1 className="h1 text-black mb-0 mt-2">Operation Team</h1>
            <p className="p">
              The working team, responsible for the day-to-day operations and
              implementation of the project, can be titled the Operations Team.
              This segment encompasses all individuals actively involved in
              executing tasks and achieving objectives
            </p>

            <div className="row">
              {teamData.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center w-100 mt-4 mb-5">
                  <Spinner />
                </div>
              ) : teamData?.length > 0 ? (
                teamData.map((item, index) => (
                  <div className="col-md-6 col-lg-4" key={index}>
                    <Link to={`/team/detail/${item?.uid}`}>
                      <div className="single-team-wrap bg-white text-center border rounded p-4 my-3 team-card-size">
                        {/* <img
              src={item?.image}
              alt="team image"
              width="120"
              className="img-fluid m-auto pb-4"
            /> */}

                        <div
                          className="team-profile-image img-fluid m-auto"
                          style={{
                            backgroundImage: `url(${item?.image})`,
                          }}
                          alt={`${item?.title} image`}
                        ></div>
                        <div className="team-content">
                          <div>
                            <h4 className="mb-2 mt-3">{item?.title}</h4>
                            <span style={{ color: "#696969" }}>
                              {item?.designation}
                            </span>
                            <p className="mt-4" style={{ color: "#696969" }}>
                              {item?.short_description}
                            </p>
                          </div>
                          {/* Uncomment the following lines if you want to display social media icons */}
                          {/* <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                <li className="list-inline-item">
                  <a className="facebook" href="#" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="twitter" href="#" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="dribbble" href="#" target="_blank">
                    <i className="fab fa-dribbble"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="linkedin" href="#" target="_blank">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
              </ul> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center w-100 mt-4">
                  <h4>No team data available.</h4>
                </div>
              )}

              {/* <div className="col-md-6 col-lg-4">
                <div className="single-team-wrap bg-white text-center border rounded p-4 my-3">
                  <img
                    src="assets/img/team/team-member-2.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Kely Roy</h5>
                    <span>Lead Designer</span>
                    <p className="mt-3">
                      Monotonectally engage sticky collaborative markets
                      synergistically
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="single-team-wrap bg-white text-center border rounded p-4 my-3">
                  <img
                    src="assets/img/team/team-member-3.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Managing Director</span>
                    <p className="mt-3">
                      Assertively procrastinate standardized whereas technically
                      sound
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>

            {/* <h1 className="h1 text-black mb-0 mt-2">Advisory Team</h1>
            <p className="p">
              The panel of experts from the IT and healthcare industries who
              provide guidance, expertise, and strategic advice form our
              Advisory Board. This segment plays a critical role in offering
              insights and recommendations to ensure the project aligns with
              industry standards and best practices.
            </p>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="single-team-wrap bg-white text-center border rounded p-4 my-3">
                  <img
                    src="assets/img/team/team-member-1.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Richard Ford</h5>
                    <span>Instructor of Mathematics</span>
                    <p className="mt-3">
                      Authoritatively engage leading-edge processes tactical
                      capital{" "}
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="single-team-wrap bg-white text-center border rounded p-4 my-3">
                  <img
                    src="assets/img/team/team-member-2.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Kely Roy</h5>
                    <span>Lead Designer</span>
                    <p className="mt-3">
                      Monotonectally engage sticky collaborative markets
                      synergistically
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="single-team-wrap bg-white text-center border rounded p-4 my-3">
                  <img
                    src="assets/img/team/team-member-3.png"
                    alt="team image"
                    width="120"
                    className="img-fluid m-auto pb-4"
                  />
                  <div className="team-content">
                    <h5 className="mb-0">Gerald Nichols</h5>
                    <span>Managing Director</span>
                    <p className="mt-3">
                      Assertively procrastinate standardized whereas technically
                      sound
                    </p>
                    <ul className="list-inline social-list-default social-color icon-hover-top-bottom">
                      <li className="list-inline-item">
                        <a className="facebook" href="#" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="twitter" href="#" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="dribbble" href="#" target="_blank">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>

      <Newsletter />
      <Footer />
    </>
  );
}

export default LeaderShip;
