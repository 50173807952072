import React, { useState } from "react";

function WhyChoose() {
  const [hoveredCards, setHoveredCards] = useState({});

  const handleMouseEnter = (cardId) => {
    setHoveredCards((prevHoveredCards) => ({
      ...prevHoveredCards,
      [cardId]: true,
    }));
  };

  const handleMouseLeave = (cardId) => {
    setHoveredCards((prevHoveredCards) => ({
      ...prevHoveredCards,
      [cardId]: false,
    }));
  };

  return (
    <div>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <div className="section-heading text-center">
                <h2>Dynamic Three-Layered Team Approach</h2>
                <p>
                  At D-Right, we embrace a dynamic team structure consisting of
                  three layers, each contributing to our success.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center justify-content-sm-center">
            <div className="col-md-6 col-lg-4">
              <div
                className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4"
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={() => handleMouseLeave(1)}
              >
                <div className="card-body">
                  <div className="pb-2">
                    <img
                      src={
                        hoveredCards[1]
                          ? "/assets/img/YoungEnterpreneurs_white.png"
                          : "/assets/img/YoungEnterpreneurs.png"
                      }
                      className="img-fluid"
                      alt="about"
                    />
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>YOUNG ENTREPRENEURS</h5>
                    <p className="mb-0">
                      Lead the charge, bringing fresh perspectives and
                      innovative ideas
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div
                className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4"
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={() => handleMouseLeave(2)}
              >
                <div className="card-body">
                  <div className="pb-2">
                    <img
                      src={
                        hoveredCards[2]
                          ? "/assets/img/ExperiencedOperations_white.png"
                          : "/assets/img/ExperiencedOperations.png"
                      }
                      className="img-fluid"
                      alt="about"
                    />
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>EXPERIENCED OPERATIONS</h5>
                    <p className="mb-0">
                      Versatile operations team with extensive experience from 5
                      to 15 years
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div
                className="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4"
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={() => handleMouseLeave(3)}
              >
                <div className="card-body">
                  <div className="pb-2">
                    <img
                      src={
                        hoveredCards[3]
                          ? "/assets/img/ExpertAdvisor_white.png"
                          : "/assets/img/ExpertAdvisor.png"
                      }
                      className="img-fluid"
                      alt="about"
                    />
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>EXPERT ADVISORS</h5>
                    <p className="mb-0">
                      National and international experts in both the medical and
                      IT fields
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default WhyChoose;
