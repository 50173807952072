import React from "react";
import { Puff } from "react-loader-spinner";

const Spinner = () => {
  return (
    <>
      <Puff
        height={100}
        width={100}
        radius={5}
        color="#00345E"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        // wrapperStyle=""
        visible={true}
      />
    </>
  );
};

export default Spinner;
