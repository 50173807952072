import React from 'react';

function About2() {
    return (
            <section id="about" className="ptb-100 gray-light-bg">
                <div className="container">
                    <div className="section-heading text-center mb-5">
                        <h2>Operational Reliability and Efficiency</h2>
                        <p>We prioritize reliability, minimizing downtime and maximizing
                            productivity for healthcare providers nationwide. Our all software programs are:
                        </p>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12 col-lg-6">
                            <div className="feature-contents section-heading">


                                <ul className="check-list-wrap py-3">
                                    <li><b>Objective Driven:</b> Our entire software range is meticulously designed to align
                                        with your specific goals and objectives, ensuring every feature serves your
                                        needs.
                                    </li>
                                    <li><b>Comprehensive:</b> Offering a complete solution, our software range covers all
                                        aspects of your requirements, leaving no gaps in functionality or features.
                                    </li>
                                    <li><b>Scalable:</b> Its number of features can be increased or decreased as per the size
                                        of the business and budget of the client to best match diverse customer needs.
                                    </li>
                                    <li><b>Reliable:</b> Trust is paramount, and our software range consistently delivers
                                        dependable results, maintaining stability and consistency in every use case.
                                    </li>
                                    <li><b>User Friendly:</b> Designed with user friendly interfaces and workflows, and
                                        prioritizes user comfort, making it easy for anyone to navigate and utilize
                                        effectively.
                                    </li>
                                    <li><b>Customer Support:</b> With responsive and knowledgeable support channels, we're
                                        committed to assisting you at every step, ensuring your experience satisfying.

                    
                                    </li>
                                
                                </ul>

                                {/*<div className="action-btns mt-3">*/}
                                {/*    <a href="#" className="btn btn-brand-02 mr-3">Get Start Now</a>*/}
                                {/*    <a href="#" className="btn btn-outline-brand-02">Learn More</a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="mask-image">
                                <img src="/assets/img/home_new.png" className="img-fluid" alt="about"/>
                                {/*<div className="item-icon video-promo-content">*/}
                                {/*    <a href="https://www.youtube.com/watch?v=9No-FiEInLA"*/}
                                {/*       className="popup-youtube video-play-icon text-center m-auto"><span*/}
                                {/*        className="ti-control-play"></span> </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default About2;
