import React, { useState } from 'react';

function Faqs() {

    const [activeAccordion, setActiveAccordion] = useState(null);

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const faqData = [
        {
            question: "What differentiates D-RIGHT from other IT companies?",
            answer: "D-Right Technologies distinguishes itself with a unique three-layered team approach, combining youthful entrepreneurship, seasoned operational expertise, and expert advisory guidance."
        },
        {
            question: "What healthcare software solutions do you offer?",
            answer: "We offer a comprehensive range of healthcare software programs, including hospital management systems, pharmacy software, pathology laboratory solutions, specialized clinical software, and much more."
        },
        {
            question: "How do you ensure data security and privacy?",
            answer: "We employ advanced encryption protocols, secure access controls, and regular security audits to safeguard sensitive patient data and ensure compliance with privacy regulations."
        },
        {
            question: "Can software solutions be customized to fit specific needs?",
            answer: "Yes, we offer customizable solutions tailored to the unique requirements of each client, ensuring seamless integration with existing workflows and systems."
        },
        {
            question: "What level of technical support do you provide?",
            answer: "We provide comprehensive technical support to our clients, including assistance with implementation, training, troubleshooting, and ongoing maintenance."
        },
        {
            question: "Are you compliant with healthcare regulatory standards?",
            answer: "Yes, we adhere to industry best practices and regulatory requirements to ensure compliance with healthcare standards and regulations."
        },
        {
            question: "How scalable are your software solutions?",
            answer: "Our software solutions are designed with scalability in mind, allowing them to grow and adapt to the evolving needs of healthcare providers and organizations."
        },
        {
            question: "Can you assist with data migration from existing systems?",
            answer: "Yes, we provide assistance with data migration to ensure a smooth transition from legacy systems to our advanced software solutions."
        },
        {
            question: "Do you offer training for users of its software programs?",
            answer: "Yes, we offer comprehensive training programs to ensure that users are proficient in utilizing our software solutions effectively."
        },
        {
            question: "How do you handle software updates and upgrades?",
            answer: "We provide regular updates and upgrades to our software solutions to incorporate new features, enhance performance, and address emerging needs in the healthcare industry."
        },
        {
            // question: "Can your software solutions integrate with other technologies?",
            question: "Can your software integrate with other technologies?",
            answer: "Yes, our software solutions are designed for seamless integration with a wide range of third-party systems and technologies."
        },
        {
            question: "Can anyone be the part of your software marketing team?",
            answer: "To explore partnership opportunities, please contact us directly to discuss your interests and qualifications."
        },
        {
            question: "Do you offer cloud-based solutions?",
            answer: "Yes, we offer cloud-based solutions to accommodate the diverse needs and preferences of our clients."
        },
        {
            question: "How do you ensure the reliability of your software solutions?",
            answer: "We implement rigorous testing procedures and quality assurance measures to ensure the reliability and stability of our software programs."
        },
        // {
        //     question: "Can you refer any successful implementation?",
        //     answer: "Yes, we can provide references and case studies upon request to demonstrate the effectiveness and impact of our software solutions."
        // }
    ];



    return (
        <div>
        <section id="faq" className="ptb-100 white-light-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-md-12 col-12 ">
                        <div className="section-heading text-center mb-5">
                            <h2>Frequently Asked Queries</h2>
                            {/* <p className=''>Efficiently productivate reliable paradigms before ubiquitous models. Continually
                                utilize frictionless expertise whereas tactical relationships. Still have questions?
                                Contact us</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    {faqData.map((item, index) => (
                        <div key={index} className="col-lg-6">
                            <div id="accordion" className="accordion faq-wrap">
                                <div className="card my-3">
                                    <div
                                        className={`card-header ${activeAccordion === index ? '' : 'collapsed'}`}
                                        onClick={() => toggleAccordion(index)}>
                                        <h6 className="mb-0 d-inline-block">{item.question}</h6>
                                    </div>
                                    <div className={`collapse ${activeAccordion === index ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#accordion">
                                        <div className="card-body white-bg">
                                            <p>{item.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    </div>
    )
}

export default Faqs;
