import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Pages/Main.css";

function HeroSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
    {
      title: "Empowering Healthcare Professionals Nationwide",
      description:
        "Experience innovation like never before with D-Right Technologies (Pvt.) Ltd. Explore our comprehensive suite of healthcare software programs, setting new standards in healthcare across Pakistan.",
      image: "/assets/img/banner2.png",
    },
    {
      title: "Healthcare Software Programs in Pakistan",
      description:
        "Explore our comprehensive suite of healthcare software programs, setting new standards in medical technology across Pakistan. Our advanced software solutions cater to every aspect of medical practice.",
      image: "/assets/img/banner1.png",
    },
    {
      title: "Transforming Hospitals with Technology",
      description:
        "Elevate healthcare efficiency, patient care and administration with D-Right Technologies (Pvt.) Ltd. We offer complete range of healthcare software programs with unparalleled integration and functionality",
      image: "/assets/img/banner3.png",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1500,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992, 
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div>
      <section className="position-relative bg-image  pt-100" image-overlay="1">
        <div
          className="background-image-wraper bg-position-1"
          style={{
            background: `url('/assets/img/hero-new-bg.svg')`,
            opacity: "1",
          }}
        ></div>
        <div className="container">
          <div className="hero-slider-padding" >
            <Slider {...settings}>
              {data.map((item, index) => (
                <div key={index}>
                  <div className="row hero-slider-animation">
                    <div
                      className={`col-lg-6 col-md-12 col-12 section-heading hero-slider-content home-sliderr `}
                    >
                      <h2
                        className={`font-weight-bold ${currentSlide === index ? "slide-in-h2" : ""
                          }`}
                      >
                        {item?.title}
                      </h2>
                      <p
                        className={`${currentSlide === index ? "slide-in-p" : ""
                          }`}
                      >
                        {item?.description}
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-12 col-12"></div>
                    <div className="col-lg-5 col-md-12 col-12 hero-slider-image">
                      <img
                        src={item?.image}
                        alt="shape"
                        className={`w-100 ${currentSlide === index ? "slide-in-image" : ""
                          }`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HeroSlider;
