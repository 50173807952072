import React from 'react'

function Newsletter() {
    return (
        <div className=" gradient-bg ptb-60 footer-with-newsletter hide d-none" >
            <div className="container">
                <div className="row newsletter-wrap primary-bg rounded shadow-lg p-5">
                    <div className="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
                        <div className="newsletter-content text-white">
                            <h3 className="mb-0 text-white web">Subscribe our Newsletter</h3>
                            <p className="mb-0">We’re a team of non-cynics who truly care for our work.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-5">
                        <form className="newsletter-form position-relative">
                            <input type="text" className="input-newsletter form-control" placeholder="Enter your email"
                                   name="email" required="" autoComplete="off"/>
                            <button type="submit" className="disabled"><i className="fas fa-paper-plane"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Newsletter
