import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      {/* <!--footer section start--> */}
      {/* <!--when you want to remove subscribe newsletter container then you should remove .footer-with-newsletter class--> */}
      <footer className="footer-1 gradient-bg ptb-60 ">
        {/* <!--subscribe newsletter start--> */}

        {/* <!--subscribe newsletter end--> */}

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
              <a href="#" className="navbar-brand mb-2">
                <img src="/assets/logo.png" alt="logo" className="img-fluid" />
              </a>
              <br />
              <p>
                D-RIGHT Technologies (Pvt.) Ltd is committed to harnessing the
                power of technology to revolutionize healthcare delivery,
                improve patient-care, and drive operational excellence across
                the entire healthcare ecosystem. Join us in shaping the future
                of healthcare with innovative software.
              </p>
              <div className="list-inline social-list-default background-color social-hover-2 mt-2">
                {/* <li className="list-inline-item">
                  <a className="twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li> */}
                <li className="list-inline-item">
                  <a className="youtube" href="https://www.youtube.com/@D-RightTechnologies" target="_blank">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/company/d-right-technologies/" target="_blank"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="instagram"
                    href="https://www.instagram.com/d_.right/" target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="facebook"
                    href="https://www.facebook.com/D.Right.Technologies/" target="_blank"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="row mt-0">
                <div className="col-sm-7 col-md-3 col-lg-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="text-uppercase">Other Resources</h6>
                  <ul>
                    <li>
                      <Link to="/vision-mission">Vision & Mission</Link>
                    </li>
                    <li>
                      <Link to="/about-founder">About the Founder</Link>
                    </li>
                    <li>
                      <Link to="/founder-message">Founder’s Message</Link>
                    </li>
                    <li>
                      <Link to="/teams">Team</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-5 col-md-3 col-lg-6 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                  <h6 className="text-uppercase">Portfolio</h6>
                  <ul>
                    <li>
                      <Link to="/web-builder">Web Builder</Link>
                    </li>
                    <li>
                      <Link to="/laris">LARIS</Link>
                    </li>
                    <li>
                      <Link to="/maps">MAPS</Link>
                    </li>

                    <li>
                      <Link to="/pipeline-product">Quest</Link>
                    </li>
                  </ul>
                </div>
                {/*<div className="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">*/}
                {/*    <h6 className="text-uppercase">Company</h6>*/}
                {/*    <ul>*/}
                {/*        <li>*/}
                {/*            <a href="#">About Us</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Careers</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Customers</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Community</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Our Team</a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/*<div className="col-sm-6 col-md-3 col-lg-3">*/}
                {/*    <h6 className="text-uppercase">Support</h6>*/}
                {/*    <ul>*/}
                {/*        <li>*/}
                {/*            <a href="#">FAQ</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Sells</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Contact Support</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Network Status</a>*/}
                {/*        </li>*/}
                {/*        <li>*/}
                {/*            <a href="#">Product Services</a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
        {/* <!--end of container--> */}
      </footer>
      {/* <!--footer bottom copyright start--> */}
      <div className="footer-bottom py-3 gray-light-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="copyright-wrap small-text text-center">
                <p className="mb-0">
                  Copyright © 2024 ClinTa - Powered by D-Right Technologies.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-5 hide d-none">
              <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <a className="small-text" href="#">
                      Terms
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="small-text" href="#">
                      Security
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="small-text" href="#">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="scroll-top scroll-to-target primary-bg text-white"
        data-target="html"
      >
        <span className="fas fa-hand-point-up"></span>
      </div>
    </div>
  );
}

export default Footer;
