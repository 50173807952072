import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import Gallery from "./Pages/Gallery";
import LabSoftware from "./Pages/LabSoftware";
import PharmacySoftware from "./Pages/PharmacySoftware";
import PipelineProducts from "./Pages/PipelineProducts";
import AboutUs from "./Pages/AboutUs";
import VisionMission from "./Pages/VisionMission";
import AboutFounder from "./Pages/AboutFounder";
import FounderMessage from "./Pages/FounderMessage";
import LeaderShip from "./Pages/LeaderShip";
import Operations from "./Pages/Operations";
import NotFound404 from "./Pages/404";
import PathologyLab from "./Pages/PathologyLab";
import PharmacyOutlets from "./Pages/PharmacyOutlets";
import ForDoctor from "./Pages/ForDoctor";
import Endorsements from "./Pages/Endorsements";
import ScrollToTop from "./Components/ScrollToTop";
import AdvisoryBoard from "./Pages/AdvisoryBoard";
import { SendPostRequest } from "./http";
import { useDispatch } from "react-redux";
import { addBranding } from "./store/reducers/brandingSlice";
import { useEffect } from "react";
import WebBuilder from "./Pages/WebBuilder";
import TeamDetail from "./LabSoftware/TeamDetail";

function App() {
  const dispatch = useDispatch();
  const fetchBranding = async () => {
    try {
      const response = await SendPostRequest("branding/info");
      dispatch(addBranding(response?.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchBranding();
  }, []);
  return (
    <>
      <ScrollToTop />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vision-mission" element={<VisionMission />} />
          <Route path="/about-founder" element={<AboutFounder />} />
          <Route path="/founder-message" element={<FounderMessage />} />
          <Route path="/teams" element={<LeaderShip />} />
          <Route path="/team/detail/:id" element={<TeamDetail />} />
          {/*<Route path="/operations" element={<Operations />} />*/}
          {/*<Route path="/advisory-board" element={<AdvisoryBoard />} />*/}
          <Route path="/pathology-lab" element={<PathologyLab />} />
          <Route path="/pharmacy-outlets" element={<PharmacyOutlets />} />
          <Route path="/for-doctors" element={<ForDoctor />} />

          {/* <Route path="/about-us" element={<AboutUs />} /> */}
          <Route path="/endorsements" element={<Endorsements />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/laris" element={<LabSoftware />} />
          <Route path="/maps" element={<PharmacySoftware />} />
          <Route path="/pipeline-product" element={<PipelineProducts />} />
          <Route path="/web-builder" element={<WebBuilder />} />
          <Route path="/*" element={<NotFound404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
