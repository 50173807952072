import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Pages/Main.css";

function WebBuilderSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
    {
      title: "Doctor Web-Builder",
      description:
        "Empower your practice with our Web-Builder tailored for doctors. Build your website effortlessly, showing your expertise, services, and patient care philosophy. No technical skills needed.",
      image: "/assets/img/doc_webbuilder.png",
      // image: "/assets/img/macbook.png",
    },

    {
      title: "Hospital Web-Builder",
      description:
        "Transform your hospital's digital presence with our Web-Builder. Easily design a comprehensive website highlighting your facilities, specialties, and everything you want without any technical know-how.",
        image: "/assets/img/hosp_webbuilder.png",
    },

    {
      title: "Pathology Laboratory Web-Builder",
      description:
        "Unlock the potential of your pathology lab with our Web-Builder. Build a professional website in minutes, showing your diagnostic services, advanced technology, and commitment to precision. Make your website without any technical expertise!",
      image: "/assets/img/macbook.png",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <div className="webBuilder_slider_container">
      <div className="container">
        <Slider {...settings}>
          {data?.map((item, index) => (
            <div key={index}>
              <div className="row hero-slider-animation">
                <div className="col-lg-12 col-md-12 col-12 webBuilder_slider_content">
                  <h2
                    className={`font-weight-bold ${currentSlide === index ? "slide-in-h2" : ""
                      }`}
                  >
                    {item?.title}
                  </h2>
                  <p
                    className={`${currentSlide === index ? "slide-in-p" : ""}`}
                  >
                    {item?.description}
                  </p>

                  <img
                    src={item?.image}
                    alt="shape"
                    className={`${currentSlide === index ? "bottom-shape img-fluid" : ""
                      }`}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default WebBuilderSlider;
