import React from 'react';

function WorkProcess() {
    return (
        <div>

            <section id="process" className="work-process-section position-relative ptb-100 white-light-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10">
                            <div className="section-heading text-center mb-5">
                                <h2 className='laris'> Discover LARIS
                                    Tailored Solutions for Every Lab</h2>
                                <p>Explore our three editions of LARIS software, each designed to meet the unique needs
                                    of pathology laboratories. From essential features in the Basic Edition to higher
                                    level functionalities in the Premium Edition, LARIS offers scalable solutions to
                                    optimize lab operations and drive efficiency. Choose the edition that best fits your
                                    lab's requirements and unlock the full potential of pathology management.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-md-center justify-content-sm-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="work-process-wrap">
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon mr-4">
                                                <i className="fas fa-project-diagram laris-color-primary"></i>
                                            </div>
                                            <div className="process-content text-left">
                                                <h5>Essential Features </h5>
                                                {/*<p>Holisticly architect sustainable meta-services for process-centric core competencies. Enthusiastically re-engineer best-of-breed outsourcing.</p>*/}
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)"
                                                  stroke-width="1" stroke-dasharray="1300" stroke-dashoffset="0"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"></path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                                  stroke-dasharray="6" stroke-dashoffset="1300"
                                                  d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item right-shape">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon ml-4">
                                                <i className="fas fa-puzzle-piece laris-color-primary"></i>
                                            </div>
                                            <div className="process-content text-right">
                                                <h5>Scalable solutions </h5>
                                                {/*<p>Monotonectally harness holistic web-readiness after multimedia based catalysts for change. Completely brand front-end systems before visionary.</p>*/}
                                            </div>
                                        </div>
                                        <svg x="0px" y="0px" width="312px" height="130px">
                                            <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)"
                                                  stroke-width="1" stroke-dasharray="1300" stroke-dashoffset="0"
                                                  d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                            <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2"
                                                  stroke-dasharray="6" stroke-dashoffset="1300"
                                                  d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="process-single-item">
                                    <div className="process-icon-item left-shape mb-0">
                                        <div className="d-flex align-items-center">
                                            <div className="process-icon mr-4">
                                                <i className="fas fa-truck laris-color-primary"></i>
                                            </div>
                                            <div className="process-content text-left">
                                                <h5>Full Potential</h5>
                                                {/*<p>Monotonectally plagiarize synergistic e-business for stand-alone communities. Professionally enhance visionary manufactured products progressive.</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="img-wrap">
                                <img src="/assets/img/ClintaLarisinfg1.png" alt="features" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WorkProcess;
