import axios from 'axios';


const server = "https://api.dright.net/";

const headers = {
    'Content-Type': 'application/json', 
    'dright-api-key': 'fdd2ec5f8b3498f28c4ffb2f3d4c7cfe09fad9d1', 
    'Cookie': 'ci_session=221e8d06b0dde481192782722d07d475b2a78001',
    'Access-Control-Allow-Origin': '*'
}


export async function SendPostRequest(endpoint, data = {}) {
    const response = await axios.post(server + endpoint, data, {
        headers: headers,
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log("error", error)
            return error;
        });
    return response.data;
}
