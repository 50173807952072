// reducers/brandingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const brandingSlice = createSlice({
  name: 'branding',
  initialState: {
    value: null,
  },
  reducers: {
    addBranding: (state, action) => {
      state.value= action.payload;
    }
  },
});

export const { addBranding } = brandingSlice.actions;
export default brandingSlice.reducer;
