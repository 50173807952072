import React, { useEffect } from "react";
import Header from "../Components/Header";
import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import AboutCustomerSlider from "../Components/AboutCustomerSlider";

function FounderMessage() {
  var BreadCrumbTitle = "Founder Message :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      <div className="main">
        <section
          className="page-header-section ptb-100 bg-image"
          image-overlay="7"
        >
          <div
            className="background-image-wraper"
            //  style={{background: `url('/assets/img/slider-bg-1.jpg')`, opacity: "1"}}
            style={{
              background: `url('/assets/img/foundermessage-banner.jpg')`,
              opacity: "1",
            }}
          ></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-9 col-lg-7">
                <div className="page-header-content text-white pt-4">
                  <h1 className="text-white mb-0">Founder’s Message</h1>
                  {/*<p className="lead">Rapidiously deploy world-className platforms whereas collaborative interfaces. Phosfluorescently facilitate corporate innovation via excellent web <services></services> technically sound. </p>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="breadcrumb-bar gray-light-bg border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="custom-breadcrumb">
                  <ol className="breadcrumb pl-0 mb-0 bg-transparent">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item">About us</li>
                    <li className="breadcrumb-item active">
                      Founder’s Message
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-hidden">
          <section
            id="about"
            className="position-relative overflow-hidden feature-section ptb-100  "
          >
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-12 col-lg-12">
                  <h2>Founder’s Message</h2>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="feature-contents section-heading">
                    <p>
                      Welcome to D-Right Technologies, where innovation meets
                      compassion to redefine the landscape of healthcare in
                      Pakistan. As the Founder and CEO, it is my privilege to
                      extend a warm greeting to you all.
                      <br />
                      <br />
                      From a young age, I harbored a fervent dream of harnessing
                      the power of technology to revolutionize our healthcare
                      industry. Today, that dream manifests in D-Right
                      Technologies, a beacon of hope for a future where
                      healthcare knows no bounds.
                      <br />
                      <br />
                      Our journey is not merely about technological advancement;
                      it's about creating a world where every individual has
                      access to quality healthcare, irrespective of barriers.
                      With unwavering dedication and a team of passionate
                      individuals, we are committed to pioneering solutions that
                      transcend limitations and empower communities.
                      <br />
                      <br />
                      Join us in our quest to transform healthcare, one
                      innovation at a time.
                      <br />
                      <br />
                      <strong>
                        Warm Regards,
                        <br />
                        Haadiyah Haque
                        <br />
                        Founder & CEO,
                        <br /> D-Right Technologies
                      </strong>
                    </p>

                    {/*<ul className="check-list-wrap list-two-col py-3">*/}
                    {/*    <li>Data driven quality review</li>*/}
                    {/*    <li>Secure work environment</li>*/}
                    {/*    <li>24x7 coverage</li>*/}
                    {/*    <li>Lifetime updates</li>*/}
                    {/*    <li>Management team</li>*/}
                    {/*    <li>Tech support</li>*/}
                    {/*    <li>Integration ready</li>*/}
                    {/*    <li>Tons of assets</li>*/}
                    {/*    <li>Compliance driven process</li>*/}
                    {/*    <li>Workforce management</li>*/}
                    {/*</ul>*/}

                    {/*<div className="action-btns mt-4">*/}
                    {/*    <a href="#" className="btn btn-brand-02 mr-3">Get Start Now</a>*/}
                    {/*    <a href="#" className="btn btn-outline-brand-02">Learn More</a>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="mask-image">
                    <img
                      src="/assets/img/about1.jpg"
                      className="img-fluid"
                      alt="about"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*<section className="position-relative overflow-hidden ptb-100">*/}
        {/*    <div className="background-image-wraper mask-65"></div>*/}
        {/*    <div className="container">*/}
        {/*        <div className="row justify-content-center">*/}
        {/*            <div className="col-md-9 col-lg-8">*/}
        {/*                <div className="section-heading text-center text-white">*/}
        {/*                    <h2 className="text-white">The Most Uses App Platform</h2>*/}
        {/*                    <p>Start working with that can provide everything you need to generate awareness, drive traffic, connect. Efficiently transform granular value with client-focused content. Energistically redefine market.</p>*/}
        {/*                </div>*/}
        {/*                <div className="video-promo-content my-5">*/}
        {/*                    <a href="https://www.youtube.com/watch?v=9No-FiEInLA" className="popup-youtube video-play-icon text-center m-auto"><span className="ti-control-play"></span> </a>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="row">*/}
        {/*            <div className="col-sm-6 col-md-6 col-lg-3">*/}
        {/*                <div className="bg-white p-5 rounded shadow count-data text-center mt-4">*/}
        {/*                    <span className="fas fa-users  color-primary icon-size-lg mb-2"></span>*/}
        {/*                    <h3 className="count-number mb-1 color-secondary font-weight-bolder">21023</h3>*/}
        {/*                    <h6 className="mb-0">Customers</h6>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-sm-6 col-md-6 col-lg-3">*/}
        {/*                <div className="bg-white p-5 rounded shadow count-data text-center mt-4">*/}
        {/*                    <span className="fas fa-cloud-download-alt  color-primary icon-size-lg mb-2"></span>*/}
        {/*                    <h3 className="count-number mb-1 color-secondary font-weight-bolder">44023</h3>*/}
        {/*                    <h6 className="mb-0">Downloads</h6>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-sm-6 col-md-6 col-lg-3">*/}
        {/*                <div className="bg-white p-5 rounded shadow count-data text-center mt-4">*/}
        {/*                    <span className="fas fa-smile  color-primary icon-size-lg mb-2"></span>*/}
        {/*                    <h3 className="count-number mb-1 color-secondary font-weight-bolder">35023</h3>*/}
        {/*                    <h6 className="mb-0">Satisfied</h6>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-sm-6 col-md-6 col-lg-3">*/}
        {/*                <div className="bg-white p-5 rounded shadow count-data text-center mt-4">*/}
        {/*                    <span className="fas fa-mug-hot  color-primary icon-size-lg mb-2"></span>*/}
        {/*                    <h3 className="count-number mb-1 color-secondary font-weight-bolder">2323</h3>*/}
        {/*                    <h6 className="mb-0">Cup of Coffee</h6>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}
        {/*<section id="process" className="work-process-section position-relative pb-100 ">*/}
        {/*    <div className="container">*/}
        {/*        <div className="row justify-content-center">*/}
        {/*            <div className="col-md-9 col-lg-8">*/}
        {/*                <div className="section-heading text-center mb-5">*/}
        {/*                    <h2>Our Work Process</h2>*/}
        {/*                    <p>*/}
        {/*                        Professional hosting at an affordable price. Distinctively recaptiualize principle-centered core competencies through client-centered core competencies.*/}
        {/*                    </p>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="row align-items-center justify-content-md-center justify-content-sm-center">*/}
        {/*            <div className="col-md-12 col-lg-6">*/}
        {/*                <div className="work-process-wrap">*/}
        {/*                    <div className="process-single-item">*/}
        {/*                        <div className="process-icon-item left-shape">*/}
        {/*                            <div className="d-flex align-items-center">*/}
        {/*                                <div className="process-icon mr-4">*/}
        {/*                                    <i className="fas fa-project-diagram color-primary"></i>*/}
        {/*                                </div>*/}
        {/*                                <div className="process-content text-left">*/}
        {/*                                    <h5>Planning Idea</h5>*/}
        {/*                                    <p>Holisticly architect sustainable meta-services for process-centric core competencies. Enthusiastically re-engineer best-of-breed outsourcing.</p>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                            <svg x="0px" y="0px" width="312px" height="130px">*/}
        {/*                                <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1" stroke-dasharray="1300" stroke-dashoffset="0" d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"></path>*/}
        {/*                                <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2" stroke-dasharray="6" stroke-dashoffset="1300" d="M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "></path>*/}
        {/*                            </svg>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    <div className="process-single-item">*/}
        {/*                        <div className="process-icon-item right-shape">*/}
        {/*                            <div className="d-flex align-items-center">*/}
        {/*                                <div className="process-icon ml-4">*/}
        {/*                                    <i className="fas fa-puzzle-piece color-primary"></i>*/}
        {/*                                </div>*/}
        {/*                                <div className="process-content text-right">*/}
        {/*                                    <h5>Developed Final Product</h5>*/}
        {/*                                    <p>Monotonectally harness holistic web-readiness after multimedia based catalysts for change. Completely brand front-end systems before visionary.</p>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                            <svg x="0px" y="0px" width="312px" height="130px">*/}
        {/*                                <path className="dashed1" fill="none" stroke="rgb(95, 93, 93)" stroke-width="1" stroke-dasharray="1300" stroke-dashoffset="0" d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>*/}
        {/*                                <path className="dashed2" fill="none" stroke="#ffffff" stroke-width="2" stroke-dasharray="6" stroke-dashoffset="1300" d="M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"></path>*/}
        {/*                            </svg>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                    <div className="process-single-item">*/}
        {/*                        <div className="process-icon-item left-shape mb-0">*/}
        {/*                            <div className="d-flex align-items-center">*/}
        {/*                                <div className="process-icon mr-4">*/}
        {/*                                    <i className="fas fa-truck color-primary"></i>*/}
        {/*                                </div>*/}
        {/*                                <div className="process-content text-left">*/}
        {/*                                    <h5>Deliver to Customer</h5>*/}
        {/*                                    <p>Monotonectally plagiarize synergistic e-business for stand-alone communities. Professionally enhance visionary manufactured products progressive.</p>*/}
        {/*                                </div>*/}
        {/*                            </div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-md-6 col-lg-6">*/}
        {/*                <div className="img-wrap">*/}
        {/*                    <img src="assets/img/app-mobile-image-3.png" alt="features" className="img-fluid" />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}
        {/*<section className="team-two-section ptb-100 gray-light-bg">*/}
        {/*    <div className="container">*/}
        {/*        <div className="row justify-content-center">*/}
        {/*            <div className="col-md-9 col-lg-8">*/}
        {/*                <div className="section-heading text-center">*/}
        {/*                    <h2>Our Team Members</h2>*/}
        {/*                    <p>Authoritatively mesh intuitive paradigms vis-a-vis goal-oriented partnerships. Continually extend open-source outside the box thinking after focused catalysts.</p>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="row">*/}
        {/*            <div className="col-md-6 col-lg-3">*/}
        {/*                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">*/}
        {/*                    <img src="assets/img/team/team-member-1.png" alt="team image" width="120" className="img-fluid m-auto pb-4" />*/}
        {/*                    <div className="team-content">*/}
        {/*                        <h5 className="mb-0">Richard Ford</h5>*/}
        {/*                        <span>Instructor of Mathematics</span>*/}
        {/*                        <p className="mt-3">Authoritatively engage leading-edge processes tactical capital </p>*/}
        {/*                        <ul className="list-inline social-list-default social-color icon-hover-top-bottom">*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="facebook" href="#" target="_blank"><i className="fab fa-facebook-f"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="twitter" href="#" target="_blank"><i className="fab fa-twitter"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="dribbble" href="#" target="_blank"><i className="fab fa-dribbble"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="linkedin" href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a>*/}
        {/*                            </li>*/}
        {/*                        </ul>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-md-6 col-lg-3">*/}
        {/*                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">*/}
        {/*                    <img src="assets/img/team/team-member-2.png" alt="team image" width="120" className="img-fluid m-auto pb-4" />*/}
        {/*                    <div className="team-content">*/}
        {/*                        <h5 className="mb-0">Kely Roy</h5>*/}
        {/*                        <span>Lead Designer</span>*/}
        {/*                        <p className="mt-3">Monotonectally engage sticky collaborative markets synergistically</p>*/}
        {/*                        <ul className="list-inline social-list-default social-color icon-hover-top-bottom">*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="facebook" href="#" target="_blank"><i className="fab fa-facebook-f"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="twitter" href="#" target="_blank"><i className="fab fa-twitter"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="dribbble" href="#" target="_blank"><i className="fab fa-dribbble"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="linkedin" href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a>*/}
        {/*                            </li>*/}
        {/*                        </ul>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-md-6 col-lg-3">*/}
        {/*                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">*/}
        {/*                    <img src="assets/img/team/team-member-3.png" alt="team image" width="120" className="img-fluid m-auto pb-4" />*/}
        {/*                    <div className="team-content">*/}
        {/*                        <h5 className="mb-0">Gerald Nichols</h5>*/}
        {/*                        <span>Managing Director</span>*/}
        {/*                        <p className="mt-3">Assertively procrastinate standardized whereas technically sound</p>*/}
        {/*                        <ul className="list-inline social-list-default social-color icon-hover-top-bottom">*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="facebook" href="#" target="_blank"><i className="fab fa-facebook-f"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="twitter" href="#" target="_blank"><i className="fab fa-twitter"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="dribbble" href="#" target="_blank"><i className="fab fa-dribbble"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="linkedin" href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a>*/}
        {/*                            </li>*/}
        {/*                        </ul>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className="col-md-6 col-lg-3">*/}
        {/*                <div className="single-team-wrap bg-white text-center border rounded p-4 mt-4">*/}
        {/*                    <img src="assets/img/team/team-member-4.png" alt="team image" width="120" className="img-fluid m-auto pb-4" />*/}
        {/*                    <div className="team-content">*/}
        {/*                        <h5 className="mb-0">Gerald Nichols</h5>*/}
        {/*                        <span>Team Manager</span>*/}
        {/*                        <p className="mt-3">Synergistically actualize out the-box technologies before parallel process</p>*/}
        {/*                        <ul className="list-inline social-list-default social-color icon-hover-top-bottom">*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="facebook" href="#" target="_blank"><i className="fab fa-facebook-f"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="twitter" href="#" target="_blank"><i className="fab fa-twitter"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="dribbble" href="#" target="_blank"><i className="fab fa-dribbble"></i></a>*/}
        {/*                            </li>*/}
        {/*                            <li className="list-inline-item">*/}
        {/*                                <a className="linkedin" href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a>*/}
        {/*                            </li>*/}
        {/*                        </ul>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}

        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}
        {/*<section className="client-section  ptb-100">*/}
        {/*    <div className="container">*/}
        {/*        <div className="row justify-content-center">*/}
        {/*            <div className="col-md-8">*/}
        {/*                <div className="section-heading text-center mb-5">*/}
        {/*                    <h2>Our Valuable Customers</h2>*/}
        {/*                    <p>*/}
        {/*                        Rapidiously morph transparent internal or sources whereas resource sucking e-business. Conveniently innovate compelling internal.*/}
        {/*                    </p>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className="row align-items-center">*/}
        {/*            <div className="col-md-12">*/}
        {/*                <div className="owl-carousel owl-theme clients-carousel dot-indicator">*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-01.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-02.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-03.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-04.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-05.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-06.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-07.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                    <div className="item single-customer">*/}
        {/*                        <img src="assets/img/customers/clients-logo-08.png" alt="client logo" className="customer-logo" />*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}
      </div>

      <Newsletter />
      <Footer />
    </>
  );
}

export default FounderMessage;
