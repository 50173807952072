import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Pages/Main.css";

function LarisBannerSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
    {
      title: "Reliable Execution",
      description:
         " LARIS (Lab Management Software) helps to deliver consistent and dependable performance, ensuring smooth operations and accurate results. With its proven track record of reliability, it transforms your lab in pathology excellence.",
      // image: "/assets/img/hero-new-img-2.svg",
      image: "/assets/img/reliable_laris.png",
    },
    {
      title: "Innovative Approach",
      description:
        "LARIS (Lab Management Software) helps to discover the future of pathology software with LARIS's innovative approach. From advanced features to intuitive design, our software revolutionizes the way pathology labs operate. Embrace innovation and stay ahead with LARIS.",
      image: "/assets/img/innovative_laris.png",
    }
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1500,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div>
      <section className="position-relative bg-image laris-slider-background  pt-100" image-overlay="1">
        <div
          className="background-image-wraper "
          style={{
            background: `url('/assets/img/hero-bg-shape-1.svg')`,
            opacity: "1",
          }}
        ></div>
        <div className="container">
          <div className="laris-hero-slider-padding"> {/* Add this div with padding */}
            <Slider {...settings}>
              {data.map((item, index) => (
                <div key={index}>
                  <div className="row ">
                    <div
                      className={`col-lg-6 col-md-12 col-12 section-heading laris-hero-slider-content `}
                    >
                      <h2
                        className={`font-weight-bold ${currentSlide === index ? "slide-in-h2" : ""
                          }`}
                      >
                        {item?.title}
                      </h2>
                      <p
                        className={`${currentSlide === index ? "slide-in-p" : ""
                          }`}
                      >
                        {item?.description}
                      </p>
                    </div>
                    <div className="col-lg-1 col-md-12 col-12"></div>
                    <div className="col-lg-5 col-md-12 col-12">
                      <img
                        src={item?.image}
                        alt="shape"
                        className={`w-100 ${currentSlide === index ? "slide-in-image" : ""
                          }`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LarisBannerSlider;
