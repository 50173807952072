import React, { useEffect, useState } from "react";
import Newsletter from "../Components/Newsletter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

function PipelineProducts() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const faqData = [
    {
      question: "Which license do I need?",
      answer:
        "Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
    },
    {
      question: "How do I get access to a theme?",
      answer:
        "Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus. Seamlessly optimize empowered testing procedures before revolutionary processes. Progressively facilitate client-centered technologies whereas extensive users. Authoritatively.",
    },
    {
      question: "How do I see previous orders?",
      answer:
        "Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
    },
    {
      question: "Support related issues for the template?",
      answer:
        "Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table. Professionally embrace customer directed value vis-a-vis high-quality portals. Quickly underwhelm B2C users with maintainable benefits.",
    },
  ];
  var BreadCrumbTitle = "Pipeline Product :: D-Right Technologies";
  document.title = BreadCrumbTitle;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />

      <div className="main">
        <section
          className="position-relative ptb-100"
          style={{
            background: `url('/assets/img/home-shape-bg.png')center center`,
            height: "auto",
          }}
        >
          <div className="fit-cover background-image-wraper position-absolute"></div>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="section-heading py-5">
                  <h1>Pipeline Productes</h1>
                  <p className="lead">
                    Start working with that can provide everything you need to
                    generate awareness, drive traffic, connect. Efficiently
                    transform granular value with client-focused.
                  </p>
                  <div className="action-btns mt-3">
                    <a  className="btn btn-brand-03 btn-rounded mr-3">
                      Download Now
                      <i className="fas fa-cloud-download-alt pl-2"></i>
                    </a>
                    <a
                      // href="https://www.youtube.com/watch?v=1APwq1df6Mw"
                      className="popup-youtube btn btn-white btn-circle btn-icon"
                    >
                      <i className="fas fa-play"></i>{" "}
                    </a>{" "}
                    <span className="pl-2">Watch Now</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="hero-animation-img">
                  <img
                    className="img-fluid"
                    src="assets/img/hero-new-img-2.svg"
                    alt="animation image"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-blog-section ptb-100 gray-light-bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading text-center">
                  <h2>Our Latest News</h2>
                  <p>
                    Efficiently matrix robust total linkage after market
                    positioning bandwidth. Holisticly restore B2B materials
                    rather than brand flexible paradigms vis-a-vis
                    mission-critical e-commerce.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {/* <div className="col-md-6 col-lg-4">
                                <div className="single-blog-card card border-0 shadow-sm mt-4">
                                    <div className="blog-img position-relative">
                                        <img src="assets/img/blog/1.jpg" className="card-img-top" alt="blog"/>
                                       
                                    </div>
                                    <div className="card-body">
                                      
                                        <h4 className=" mb-2 card-title">ClinTa LARIS</h4>
                                        <h6 className=" mb-2 card-title"><a href="#">Revolutionize Your Lab</a></h6>
                                        <p className="card-text">Transform your pathology laboratory into a hub of
                                            efficiency and accuracy with ClinTa Laris. From sample tracking to report
                                            generation, our customizable platforms streamline workflows, ensuring
                                            precise results and faster turnaround times.</p>
                                     
                                    </div>
                                </div>
                            </div> */}
              <div className="col-md-6 col-lg-4">
                <div className="single-blog-card card border-0 shadow-sm mt-4 pipeline-single-blog-card">
                  <div className="blog-img position-relative">
                    <img
                       src="/assets/img/blog/clinta_extended.png"
                      className="card-img-top"
                      alt="blog"
                    />
                  </div>
                  <div className="card-body">
             
                    <h4 className=" mb-2 card-title">ClinTa Extended</h4>
                    <p className="card-text">
                      The comprehensive ClinTa Extended is a real hospital ERP
                      that works by integrating every department along with
                      respective operations of the hospital, to make every
                      report available to the management right at the time of
                      data entry.
                    </p>
                  </div>
                </div>
              </div>
              

              <div className="col-md-6 col-lg-4">
                <div className="single-blog-card card border-0 shadow-sm mt-4 pipeline-single-blog-card">
                  <div className="blog-img position-relative">
                    <img
                      src="/assets/img/blog/clinta_special.png"
                      className="card-img-top"
                      alt="blog"
                    />
                   
                  </div>
                  <div className="card-body">                  
                    <h4 className=" mb-2 card-title">ClinTa Special</h4>
                   
                    <p className="card-text">
                      The power of specialization at your fingertips. ClinTa
                      Special is tailored to the unique needs of different
                      clinical specialties, including Oncology, ENT, and
                      endoscopy. It offers specialized features within your
                      specific practice area.
                    </p>
                  </div>
                </div>
              </div>
             
              <div className="col-md-6 col-lg-4">
                <div className="single-blog-card card border-0 shadow-sm mt-4 pipeline-single-blog-card">
                  <div className="blog-img position-relative">
                    <img
                      src="/assets/img/blog/clinta_advance.png"
                      className="card-img-top"
                      alt="blog"
                    />
                  </div>
                  <div className="card-body">
                    <h4 className=" mb-2 card-title">ClinTa Advanced</h4>
                    <p className="card-text">
                      Built for the complexities of clinical research. ClinTa
                      Advanced offers a robust suite of features specifically
                      designed to streamline research processes. Manage
                      participants, collect data with ease, and leverage
                      powerful analysis tools.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Newsletter />
      <Footer />
    </>
  );
}

export default PipelineProducts;
